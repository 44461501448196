import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import home from '../pages/masjedi/home.vue'
import agents from '../pages/masjedi/agents.vue'
import requests from '../pages/masjedi/requests.vue'
import requestStatus from '../pages/masjedi/requestStatus.vue'
import requestType from '../pages/masjedi/requestType.vue'
import errorType from '../pages/masjedi/errorType.vue'
import reports from '../pages/masjedi/reports.vue'
import error from '../pages/masjedi/error.vue'

import AssessorLayout from '../pages/Layout/AssessorLayout.vue'
import assessor from '../pages/masjedi/assessor.vue'


import login from '../pages/login.vue'
import Router from 'vue-router'


const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  // scrollBehavior() {
  //     return {x: 0, y: 0}
  // },
  linkExactActiveClass:'nav-item active',
  routes:[
    {
      path:'/',
      // redirect:'/home',
      component: DashboardLayout,
      children:[
        {
          path:'/',
          component:home,
        },
        {
          path:'requests',
          component: requests,
        },
        {
          path:'reports',
          component: reports,
        },
        {
          path:'agents',
          component:agents
        },
        {
          path:'requestStatus',
          component:requestStatus
        },
        {
          path:'requestType',
          component:requestType
        },
        {
          path:'errorType',
          component:errorType
        },
        {
          path:'errors',
          component:error
        },
      ] 
    },
    {
      path:'/assessment',
      component: AssessorLayout,
      redirect:'',
      children:[
        {
          path:'',
          component: assessor
        }
      ]
    },
    {
      path:'/login',
      component: login
    }
  ]
})


// const routes = [
//   {
//     path: "/",
//     component: DashboardLayout,
//     redirect: "/content",
//     children: [
//       {
//         path: "content",
//         name: "محتوا",
//         component: contentManagement
//       },
//       {
//         path: "reports",
//         name: "گزارشات",
//         component: reports
//       },
//       {
//         path: "user",
//         name: "User Profile",
//         component: UserProfile
//       },
//       {
//         path: "table",
//         name: "Table List",
//         component: TableList
//       },
//       {
//         path: "typography",
//         name: "Typography",
//         component: Typography
//       },
//       {
//         path: "icons",
//         name: "Icons",
//         component: Icons
//       },
//       {
//         path: "maps",
//         name: "Maps",
//         meta: {
//           hideFooter: true
//         },
//         component: Maps
//       },
//       {
//         path: "notifications",
//         name: "Notifications",
//         component: Notifications
//       },
//       {
//         path: "upgrade",
//         name: "Upgrade to PRO",
//         component: UpgradeToPRO
//       }
//     ]
//   }
// ];

export default router;
