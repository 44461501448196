<template>
    <div class="row">
        <div class="col-12">
            <div class="row mt-2 pr-3 py-2 font">
                <div class="col-lg-2">
                    <!-- <label >از تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="start_date" id="start_date" placeholder="از تاریخ">
                    <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <!-- <label >تا تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                    <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <button class="btn btn-primary float-right py-1" @click="applyFilter()">دریافت</button>
                </div>
            </div>
            <div class="row mt-1 px-3">
                <div class="col-lg-5 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">تعداد خطا<span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData="errorChartData" :options="errorOptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-7 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font"> خطا بر حسب کارگزار <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData="agentChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>
 
                </div>
            </div>
            <div class="row mb-2 px-3">
                <div class="col-lg-5 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">خطا بر حسب وضعیت درخواست <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData="reqStatusChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-7 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">خطا بر حسب نوع درخواست <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData="reqTypeChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import barChart from '../../components/barChart.vue'
const instance = axios.create({
    baseURL: 'https://tahavoly.ir'
})
export default {
    components:{
        barChart,
    },
    data(){
        let statuses =['تایید شده', 'رد شده', 'منقضی شده'];
        let typeColors =['#21abc9','#f95d6a']
        let statusColors = ['#1fc417 ','#f1c21b','#e91035']
        let agents=['کیاهوشان','شاکلید','حامی','آی تی ساز','پارت','یوآیدی'];
        let agentColors = ['#4fc1e8','#a0d568','#ac92eb','#ffce54','#ed5564','#488f31',]
        let errors=['201','202','203','204','205','206','207','208']
        let types=['ثبت نام','ویرایش']
        let otherErrors = ['301','302','302','303','304','305','306']
        return{
            load: false,
            ERRORS:[],
            start_date:'',
            end_date:'',
            options: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                },
                tooltips:{
                    callbacks: {
                        label: function(tooltipItem, data) {
                        //get the concerned dataset
                            
                        }
                    }
                },
                legend:{
                    labels:{
                        fontFamily:'IranSans'
                    },
                    position:'bottom'
                },
                tooltips:{
                    titleFontFamily:'IranSans',
                    bodyFontFamily:'IranSans'
                },
                responsive: true,
                maintainAspectRatio: false,
                
            },

            errorOptions: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }]
                },
                tooltips:{
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label = data.labels[tooltipItem.index]
                            let dataset = data.datasets[tooltipItem.datasetIndex]
                            let value = dataset.data[tooltipItem.index]
                            let new_label = ''
                        },
                    }
                },
                legend:{
                    display:false
                },
                responsive: true,
                maintainAspectRatio: false,
            },

            // request status chart data
            reqTypeChartData:{
                labels: errors.concat(otherErrors),
                datasets: [
                    {
                        label: types[0],
                        backgroundColor: typeColors[0],
                        data: [1028,364,405,607,3,154,3020,41],
                    },
                    {
                        label: types[1],
                        backgroundColor: typeColors[1],
                        data: [56,17,24,39,1,18,168,4],
                    },
                ]
            },

            // request type chart data
            reqStatusChartData:{
                labels: errors.concat(otherErrors),
                datasets: [
                    {
                        label: statuses[0],
                        backgroundColor: statusColors[0],
                        data: [0,0,92,151,0,0,0,0,551,187,279,122,20,4],
                    },
                    {
                        label: statuses[1],
                        backgroundColor: statusColors[1],
                        data: [1084,381,337,495,4,172,3148,45],
                    },
                    {
                        label: statuses[2],
                        backgroundColor: statusColors[2],
                        data: [],
                    },
                ]
            },

            //######################################
            agentChartData:{
                labels: errors,
                datasets: [
                    {
                        label:agents[0],
                        backgroundColor: agentColors[0],
                        data: [121,47,52,76,1,25,385,3],
                    },
                    {
                        label: agents[1],
                        backgroundColor: agentColors[1],
                        data: [96,38,47,65,0,17,307,5],
                    },
                    {
                        label:agents[2],
                        backgroundColor: agentColors[2],
                        data: [192,192,85,113,0,34,609,9],
                    },
                    {
                        label: agents[3],
                        backgroundColor: agentColors[3],
                        data: [252,99,104,162,1,43,801,13],
                    },
                    {
                        label:agents[4],
                        backgroundColor: agentColors[4],
                        data: [180,27,35,83,1,11,273,4],
                    },
                    {
                        label: agents[5],
                        backgroundColor: agentColors[5],
                        data: [243,95,106,147,1,42,773,11],
                    },
                ]
            },

            //########################################
            errorChartData:{
                labels: errors,
                datasets: [
                    {
                        label:'',
                        backgroundColor: '#00bfa0',
                        data: [1084,498,429,646,4,172,3148,45],
                    },
                ]
            },
        }
    },
    methods:{
        getErrorList(){
            instance.get('/api/error_list/').
            then(
                response=>{
                    if(response.status == 200){
                        this.ERRORS = JSON.parse(JSON.stringify(response.data.data))
                    }
                }
            )
        }
    },
    mounted(){
        this.getErrorList()
        
    }
}
</script>
<style scoped>
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../../../src/assets/font/IRANSansWeb.woff') format('woff');
}
.card{
    display: flex;
    border-radius: 1rem;
    box-shadow: 0px 4px 6px 0px #bbbbbb;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.font{
    font-family: 'IranSans';
}
.datepicker{
    border-radius: 0.5rem;
    box-shadow: 0x 2px 5px 1px #bbbbbb !important;
}

</style>