<template>
    <div class="row">
        <div class="col-12">
            <div class="row mt-2 pr-3 py-2 font">
                <div class="col-lg-2">
                    <!-- <label >از تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="start_date" id="start_date" placeholder="از تاریخ">
                    <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <!-- <label >تا تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                    <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <button class="btn btn-primary float-right py-1" @click="applyFilter()">دریافت</button>
                </div>
            </div>
            <div class="row mt-1 px-3">
                <div class="col-lg-5 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">نوع درخواست</label>
                        <bar-chart :chartData="reqTypeChartData" :options="reqStatusOptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-7 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">نوع درخواست بر حسب کارگزار</label>
                        <bar-chart :chartData="agentChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>

                </div>
            </div>
            <div class="row mb-2 px-3">
                <div class="col-lg-5 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">نوع درخواست برحسب وضعیت درخواست</label>
                        <bar-chart :chartData="reqStatusChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-7 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">نوع درخواست برحسب خطا <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData=" errorChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'jalali-moment';
import barChart from '../../components/barChart.vue'
import auth from '../../services/authService';

const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})
export default {
    components:{
        barChart,
    },
    data(){
        let errors=['201','202','203','204','205','206','207','208','301','302','303','304','305','306']
        let errorColors=['#0d6efd','#6c757d','#2ba745','#dc3545','#ffc107','#17a2b8','#ff832b','#343a40','#c04cd4','#00f76c','#00c6ff','#2f4b7c','#247e1a','#f5cbaf']
        return{
            types:['ثبت نام','ویرایش'],
            agents:[],
            agentColors:['#4fc1e8','#a0d568','#ac92eb','#ffce54','#ed5564','#488f31','#6c757d'],
            statuses:[],
            statusColors:['#4fc1e8','#a0d568','#6c757d','#ac92eb','#ffce54','#ed5564','#488f31'],
            start_date:'',
            end_date:'',
            options: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                },
                legend:{
                    labels:{
                        fontFamily:'IranSans'
                    },
                    position:'bottom'
                },
                tooltips:{
                    titleFontFamily:'IranSans',
                    bodyFontFamily:'IranSans'
                },
                responsive: true,
                maintainAspectRatio: false,
                
            },

            reqStatusOptions: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }]
                },
                legend:{
                    display:false
                    // position:'bottom'
                },
                responsive: true,
                maintainAspectRatio: false,
                
            },

            // request status chart data
            reqTypeChartData:{
                labels: this.types,
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#569dd2',
                        data: [],
                    },
                ]
            },

            // request type chart data
            reqStatusChartData:{
                labels: this.types,
                datasets: []
            },

            //######################################
            agentChartData:{
                labels: this.types,
                datasets: []
            },

            //########################################
            errorChartData:{
                labels: this.types,
                datasets: [
                    {
                        label: errors[0],
                        backgroundColor: errorColors[0],
                        data: [1028,56],
                    },
                    {
                        label: errors[1],
                        backgroundColor: errorColors[1],
                        data: [364,17],
                    },
                    {
                        label: errors[2],
                        backgroundColor: errorColors[2],
                        data: [405,24],
                    },
                    {
                        label: errors[3],
                        backgroundColor: errorColors[3],
                        data: [607,39],
                    },
                    {
                        label: errors[4],
                        backgroundColor: errorColors[4],
                        data: [3,1],
                    },
                    {
                        label: errors[5],
                        backgroundColor: errorColors[5],
                        data: [154,18],
                    },
                    {
                        label: errors[6],
                        backgroundColor: errorColors[6],
                        data: [3020,168],
                    },
                    {
                        label: errors[7],
                        backgroundColor: errorColors[7],
                        data: [41,4],
                    },
                    {
                        label: errors[8],
                        backgroundColor: errorColors[8],
                        data: [520,31],
                    },
                    {
                        label: errors[9],
                        backgroundColor: errorColors[9],
                        data: [176,11],
                    },
                    {
                        label: errors[10],
                        backgroundColor: errorColors[10],
                        data: [258,21],
                    },
                    {
                        label: errors[11],
                        backgroundColor: errorColors[11],
                        data: [113,9],
                    },
                    {
                        label: errors[12],
                        backgroundColor: errorColors[12],
                        data: [18,2],
                    },
                    {
                        label: errors[13],
                        backgroundColor: errorColors[13],
                        data: [3,1],
                    },
                ]
            },
        }
    },
    methods:{
        applyFilter(){
            const loading = this.$vs.loading({
                type:'square',
                text:'... در حال دریافت اطلاعات'
            })
            this.countPerType(this.start_date, this.end_date)
            this.countPerAgentAndType(this.start_date, this.end_date)
            this.countPerStateAndType(this.start_date, this.end_date)
            if(this.reqStatusChartData.datasets.length != 0){
                setTimeout(() => {
                    loading.close()
                }, 1500);
            }else{
                setTimeout(() => {
                    loading.close()
                }, 2000);
            }
        },
        countPerType(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_type/', {params:params}).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.reqTypeChartData = {}
                        let labels = []
                        let data = []
                        response.data.results.forEach(item =>{
                            labels=this.types
                            data.push(item.count)
                        })
                        this.reqTypeChartData = {
                            labels:labels,
                            datasets: [
                                {
                                backgroundColor: ['#21abc9','#f95d6a'],
                                borderWidth: 1,
                                //Data to be represented on y-axis
                                data:data
                                }
                            ]
                        }
                    }
                }
            ).catch(
                error =>{
                    if (error.response.status == 401) {
                        auth.logout()
                    }
            })
        },
        countPerAgentAndType(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_agent_and_type/', {params:params}).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.agentChartData = {
                            labels: this.types,
                            datasets: [
                                {
                                    label: this.agents[0],
                                    backgroundColor: this.agentColors[0],
                                    data: [response.data.results[0].register,response.data.results[0].edit],
                                },
                                {
                                    label: this.agents[1],
                                    backgroundColor: this.agentColors[1],
                                    data: [response.data.results[1].register,response.data.results[1].edit],
                                },
                                {
                                    label: this.agents[2],
                                    backgroundColor: this.agentColors[2],
                                    data: [response.data.results[2].register,response.data.results[2].edit],
                                },{
                                    label: this.agents[3],
                                    backgroundColor: this.agentColors[3],
                                    data: [response.data.results[3].register,response.data.results[3].edit],
                                },
                                {
                                    label: this.agents[4],
                                    backgroundColor: this.agentColors[4],
                                    data: [response.data.results[4].register,response.data.results[4].edit],
                                },
                                {
                                    label: this.agents[5],
                                    backgroundColor: this.agentColors[5],
                                    data: [response.data.results[5].register,response.data.results[5].edit],
                                },
                                {
                                    label: this.agents[6],
                                    backgroundColor: this.agentColors[6],
                                    data: [response.data.results[6].register,response.data.results[6].edit],
                                },
                            ]
                        }
                    }
                }
            ).catch(error=>{})
        },
        countPerStateAndType(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_state_and_type/', {params:params}).
            then(
                response=>{
                    if(response.status == 200){
                        this.reqStatusChartData = {
                            labels: this.types,
                            datasets: [
                                {
                                    label: this.statuses[0],
                                    backgroundColor: this.statusColors[0],
                                    data: [response.data.results[0].register,response.data.results[0].edit],
                                },
                                {
                                    label: this.statuses[1],
                                    backgroundColor: this.statusColors[1],
                                    data: [response.data.results[1].register,response.data.results[1].edit],
                                },
                                {
                                    label: this.statuses[2],
                                    backgroundColor: this.statusColors[2],
                                    data: [response.data.results[2].register,response.data.results[2].edit],
                                },
                                {
                                    label: this.statuses[3],
                                    backgroundColor: this.statusColors[3],
                                    data: [response.data.results[3].register,response.data.results[3].edit],
                                },
                                {
                                    label: this.statuses[4],
                                    backgroundColor: this.statusColors[4],
                                    data: [response.data.results[4].register,response.data.results[4].edit],
                                },
                                {
                                    label: this.statuses[5],
                                    backgroundColor: this.statusColors[5],
                                    data: [response.data.results[5].register,response.data.results[5].edit],
                                },
                                {
                                    label: this.statuses[6],
                                    backgroundColor: this.statusColors[6],
                                    data: [response.data.results[6].register,response.data.results[6].edit],
                                },
                            ]
                        }
                    }
                }
            ).catch(error=>{})
        },
        getAgentList(){
            instance.get('/api/agent_list/').then(
                response =>{
                    if(response.status == 200 ){
                        response.data.forEach(item =>{
                            this.agents.push(item.name)
                        })
                    }
                }
            ).catch(error=>{})
        },
        getStateList(){
            instance.get('/api/state_list/').then(
                response=>{
                    if(response.status == 200 ){
                        response.data.forEach(item =>{
                            this.statuses.push(item.title)
                        })
                    }
                }
            ).catch(error=>{})
        },
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
    },
    mounted(){
        this.getStateList()
        this.getAgentList()
        let today = new Date()
        let yesterday = today.setDate(today.getDate()-2)
        let yesterday_jalali = this.convert_to_jalali(yesterday)
        this.start_date = yesterday_jalali
        this.end_date = yesterday_jalali
        this.applyFilter()
    }
}
</script>
<style scoped>
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../../../src/assets/font/IRANSansWeb.woff') format('woff');
}
.card{
    display: flex;
    border-radius: 1rem;
    box-shadow: 0px 4px 6px 0px #bbbbbb;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.font{
    font-family: 'IranSans';
}
.datepicker{
    border-radius: 0.5rem;
    box-shadow: 0x 2px 5px 1px #bbbbbb !important;
}
</style>