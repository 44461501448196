<template>
    <div class="font px-4 pt-3">
        <div class="row">
            <div class="col-lg-2 pl-5 pl-lg-2">
                <label class="w-100 text-right text-dark-blue mb-0">از تاریخ</label>
                <input type="text" class="datepicker w-100 back-light" v-model="start_date" id="start_date" placeholder="از تاریخ">
                <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
            </div>
            <div class="col-lg-2 pl-5 pl-lg-2 mt-1 mt-lg-0">
                <label class="w-100 text-right text-dark-blue mb-0">تا تاریخ</label>
                <input type="text" class="datepicker w-100 back-light" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
            </div>
            <div class="col-lg-3 pl-5 pl-lg-2 mt-1 mt-lg-0 text-right" v-if="!isAgent">
                <label class="w-100 text-dark-blue mb-0">کارگزار</label>
                <div class="select-container pb-1 pl-1" @click="showAgentOptions()" id="agentmultipleselect">
                    <span v-for="(item,index) in agentFilters" :key="index" class="selected px-2 pb-1 text-nowrap d-inline-block mt-1 mr-1 text-12">
                        <span v-for="(agent,j) in AGENTS" :key="j">
                            <i v-if="item == agent.id" @click="removeAgent(index)" class="fas fa-close text-danger mt-1"></i>
                            <span v-if="item == agent.id" class="mr-1">{{agent.name}}</span>
                        </span>
                    </span>
                </div>
                <div v-if="showAgents" class="option-container">
                    <div v-for="(item,index) in AGENTS" :key="index" @click="agentFilterClick(index)" class="option py-1 px-2">{{item.name}}</div>
                </div>
            </div>
            <div class="col-lg-1 mt-1 mt-lg-0 pt-4">
                <button class="btn btn-primary float-right py-1" @click="applyFilter()">دریافت</button>
            </div>
            <div class="col-lg-2 mt-1 mt-lg-0 pt-4">
                <button v-if="!isAgent" class="btn btn-primary float-right py-1 text-nowrap" @click="exportErrors()">خروجی اکسل</button>
            </div>
            <div class="col-lg-2 mt-1 mt-lg-0 pt-4">
                <button v-if="isAdmin" class="btn btn-primary float-right py-1 text-nowrap" @click="retakeMedia()">دریافت مجدد</button>
            </div>
        </div>
        <div class="row pt-4" dir="rtl">
            <div class="col-12 overflow-auto">
                <table class="table table-striped text-right">
                    <thead class="back-light-blue">
                        <tr>
                            <th class="align-middle text-center">توکن</th>
                            <th class="align-middle text-center">زمان ارسال درخواست</th>
                            <th class="align-middle text-center">خطا</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="showList.length == 0">
                            <td colspan="12" class="text-center">
                                داده ای برای نمایش موجود نیست!
                            </td>
                        </tr>
                        <tr v-else v-for="(item, index) in showList" :key="index" dir="rtl" class="text-left overflow-auto">
                            <td v-if="item != null" class="text-nowrap">{{ item.token }}</td>
                            <td v-if="item != null" class="text-nowrap">{{ item.created }}</td>
                            <td v-if="item != null">{{ item.error }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3 font pb-3" dir="ltr">
            <div class="col-3 col-lg-1 pl-5">
                <button class="sarshenas-btn-dark circle float-start" @click="prevPage()">❮</button>
            </div>
            <div class="col-4 col-lg-2 text-center text-dark-blue">
                <span>صفحه ی</span> <span>{{currentPage}}</span> <span>از</span> <span>{{numOfPage}}</span>
            </div>
            <div class="col-2 col-lg-1">
                <button class="sarshenas-btn-dark ml-1 circle float-" @click="nextPage()">❯</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'jalali-moment';
import auth from '../../services/authService';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})
export default{
    data(){
        return{
            AGENTS:[],
            agentFilters:[],
            start_date:'',
            end_date:'',
            currentPage:0,
            numOfPage:0,
            pageNumber:0,
            step:50,
            count:0,
            showList:[],
            showAgents:false,
            role:'',
            isAgent:false,
            isAdmin:false,
            nextPageLink:null,
            prevPageLink:null
        }
    },
    methods:{
        applyFilter(){
            const loading = this.$vs.loading({
                type:'square',
                text:'... در حال دریافت اطلاعات',
            })
            let filter = {
                token__created__range:'',
                token__agent__in:'',
            }
            let sDate=moment.from(this.start_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            let eDate=moment.from(this.end_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')

            filter.token__created__range=sDate+','+eDate
            this.agentFilters.length != 0 ? filter.token__agent__in = this.agentFilters.toString() : filter.token__agent__in=null
            this.getErrors(filter).
            then(
                response=>{
                    if(response.status == 200){
                        this.count=response.data.count
                        this.showList = response.data.results
                        this.pagination(this.count)
                        this.nextPageLink=response.data.next
                        this.prevPageLink=response.data.previous
                    }
                }
            ).catch(
                error =>{
                    if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
            if(this.showList.length != 0){
                setTimeout(() => {
                    loading.close()
                }, 1500);
            }else{
                setTimeout(() => {
                    loading.close()
                }, 2000);
            }
        },
        getErrors(filter){
            return instance.get('/api/failed_token_list/', {params:filter})
        },
        retakeMedia(){
            instance.get('api/retake_failed_token/').
            then(
                response =>{
                    if(response.status == 200){
                        this.showNotif('در حال دریافت مجدد اظلاعات','','success')
                    }
                }
            ).
            catch(
                error=>{
                    if (error.response.status == 403){
                        this.showNotif('در حال دریافت مجدد اظلاعات','','failed')
                    }
                }
            )
        },
        showAgentOptions(){
            if(this.showAgents){
                this.showAgents = false
            }else{
                this.showAgents = true
                let agentIgnore = null
                if(this.role != 'agent'){
                    agentIgnore = document.getElementById('agentmultipleselect')
                }            
                document.addEventListener('click', (event)=>{
                    let isInsideAgent = true
                    if(this.role != 'agent'){
                        isInsideAgent = agentIgnore.contains(event.target)
                    }
                    if(!isInsideAgent){
                        this.showAgents = false
                    }
                })
            }
        },
        removeAgent(index){
            this.agentFilters.splice(index, 1)
        },
        agentFilterClick(index){
            let flag = false
            if(this.agentFilters.length!=0){
                for (let i = 0; i < this.agentFilters.length; i++) {
                    if(this.agentFilters[i] == this.AGENTS[index].id){
                        flag = true
                        break;
                    }
                }
                if(!flag){
                    this.agentFilters.push(this.AGENTS[index].id)
                }
            }
            else{
                this.agentFilters.push(this.AGENTS[index].id)
            }
        },
        getAgentList(){
            instance.get('/api/agent_list/').
            then(
                response =>{
                    if(response.status == 200 ){
                        this.AGENTS = JSON.parse(JSON.stringify(response.data))
                    }
                }
            )
        },
        exportErrors(){
            let filter = {
                start_date:'',
                end_date:'',
                agent_filter:[]
            }
            filter.start_date = this.start_date
            filter.end_date = this.end_date
            filter.agent_filter = this.agentFilters
            instance.post('api/export_error/',filter).then(
                response =>{
                    if(response.status == 200){
                        this.showNotif('درخواست گزارش با موفقیت ثبت شد','برای دریافت گزارش به صفحه گزارشات مراجعه کنید','success')
                    }
                }
            ).catch(
                error =>{
                    if(error.response.status == 404){
                        this.showNotif('داده ای برای این تاریخ یافت نشد','','warn')
                    }
                    else if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
        },
        prevPage(){
            if(this.prevPageLink != null){
                instance.get(this.prevPageLink).
                then(
                    response => {
                        this.showList = response.data.results
                        this.nextPageLink=response.data.next
                        this.prevPageLink=response.data.previous
                        this.currentPage--
                    }
                ).catch(
                    error =>{
                        if(error.response.status == 401){
                            auth.logout()
                        }
                    }
                )
            }
        },
        nextPage(){
            if(this.nextPageLink != null){
                instance.get(this.nextPageLink).
                then(
                    response => {
                        this.showList = response.data.results
                        this.nextPageLink=response.data.next
                        this.prevPageLink=response.data.previous
                        this.currentPage++
                    }
                ).catch(
                    error=>{
                        if(error.response.status == 401){
                            auth.logout()
                        }
                    }
                )
            }
        },
        pagination(count){
            if(count % this.step == 0){
                this.numOfPage = parseInt(count / this.step);
                this.numOfPage !=0 ? this.currentPage=1 : this.currentPage=0
            }else{
                this.numOfPage = parseInt(count / this.step) + 1;
                this.numOfPage !=0 ? this.currentPage=1 : this.currentPage=0
            }
        },
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
        showNotif(title, text, color){
            this.$vs.notification({
                title:title,
                text:text,
                border:color,
                position:'top-center',
            })
        }
    },
    mounted(){
        this.getAgentList()
        let today = new Date()
        let current = today.setDate(today.getDate()-2)
        let current_jalali = this.convert_to_jalali(current)
        this.start_date = current_jalali
        this.end_date = current_jalali
        this.pageNumber = 1
        this.applyFilter()
        this.isAgent = localStorage.getItem('is_agent').toLowerCase() === 'true'
        this.isAdmin = localStorage.getItem('is_staff').toLowerCase() === 'true'
    }
}
</script>
<style lang="scss" scoped>
    .font{
            font-family: 'IranSans';
        }
    .back-light-blue{
        background-color: #00bcd4;
        color: #002e5b;
    }
    .selected{
        background-color: #b0dadf;
        border:1px solid #00bcd4;
        border-radius: 0.8rem;
        color: #145da1;
    }
    .select-container{
    width: 100%;
    min-height: 32px;
    max-height: auto;
    background-color: white;
    border: 1px solid #002e5b;
    border-radius: 0.5rem;
    }
    .option-container{
        position: absolute;
        z-index: 100;
        background-color: rgb(255, 255, 255) !important;
        width: 190px;
        margin-right: 0.1rem;
        box-shadow: 1px 2px 8px 0px rgb(169, 168, 168);
    }
    .option:hover{
        background-color: #dddddd;
        cursor: pointer;
    }
    .agent-active{
        background-color: #145da1 !important;
        color: #fff !important;
    }
    .layout-auto{
        table-layout: fixed !important;
        width: 100% !important;
    }
    .overflow-scroll{
        overflow-x: scroll !important;
    }
</style>