<template>
     <div class="row">
        <div class="col-12">
            <div class="row mt-2 pr-3 py-2 font">
                <div class="col-lg-2">
                    <!-- <label >از تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="start_date" id="start_date" placeholder="از تاریخ">
                    <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <!-- <label >تا تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                    <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <button class="btn btn-primary float-right py-1" @click="applyFilter()">دریافت</button>
                </div>
            </div>
            <div class="row mt-1 px-3">
                <div class="col-lg-5 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">وضعیت درخواست</label>
                        <bar-chart :chartData="reqStatusChartData" :options="reqStatusOptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-7 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">وضعیت درخواست بر حسب کارگزار</label>
                        <bar-chart :chartData="agentChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>

                </div>
            </div>
            <div class="row mb-2 px-3">
                <div class="col-lg-5 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">وضعیت درخواست برحسب نوع درخواست</label>
                        <bar-chart :chartData="reqTypeChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-7 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">وضعیت درخواست برحسب خطا <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData=" errorChartData" :options="options" style="height:270px !important"></bar-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'jalali-moment';
import barChart from '../../components/barChart.vue'
import auth from '../../services/authService';
const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})
export default {
    components:{
        barChart,
    },
    data(){
        // let agents=['کیاهوشان','شاکلید','حامی','آی تی ساز','پارت','یوآیدی'];
        let errors=['201','202','203','204','205','206','207','208',]
        let errorColors = ['#0d6efd','#6c757d','#2ba745','#dc3545','#ffc107','#17a2b8','#ff832b','#343a40',]
        return{
            // agents:['آی تی سار','یو آیدی','آناهیتا','غدیر','آدانیک','حامی سیستم شربف','پارت']
            agents:[],
            statuses:[],
            statusColors: ['#4fc1e8','#a0d568','#6c757d','#ac92eb','#ffce54','#ed5564','#488f31'],
            typeColors:['#21abc9','#f95d6a'],
            start_date:'',
            end_date:'',
            options: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                },
                legend:{
                    labels:{
                        fontFamily:'IranSans'
                    },
                    position:'bottom'
                },
                tooltips:{
                    titleFontFamily:'IranSans',
                    bodyFontFamily:'IranSans'
                },
                responsive: true,
                maintainAspectRatio: false,
                
            },

            reqStatusOptions: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }]
                },
                legend:{
                    display:false
                    // position:'bottom'
                },
                responsive: true,
                maintainAspectRatio: false,
                
            },

            // request status chart data
            reqStatusChartData:{
                labels: [],
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#b981d9',
                        data: [],
                    },
                ]
            },

            // request type chart data
            reqTypeChartData:{
                labels: [],
                datasets: []
            },

            //######################################
            agentChartData:{
                labels: [],
                datasets: []
            },

            //########################################
            errorChartData:{
                labels: [],
                datasets: [
                    {
                        label: errors[0],
                        backgroundColor: errorColors[0],
                        data: [0,1084,0],
                    },
                    {
                        label: errors[1],
                        backgroundColor: errorColors[1],
                        data: [0,381,0],
                    },
                    {
                        label: errors[2],
                        backgroundColor: errorColors[2],
                        data: [92,337,0],
                    },
                    {
                        label: errors[3],
                        backgroundColor: errorColors[3],
                        data: [151,495,0],
                    },
                    {
                        label: errors[4],
                        backgroundColor: errorColors[4],
                        data: [0,4,0],
                    },
                    {
                        label: errors[5],
                        backgroundColor: errorColors[5],
                        data: [0,172,0],
                    },
                    {
                        label: errors[6],
                        backgroundColor: errorColors[6],
                        data: [0,3148,0],
                    },
                    {
                        label: errors[7],
                        backgroundColor: errorColors[7],
                        data: [0,45,0],
                    },
                ]
            },

        }
    },
    methods:{
        applyFilter(){
            const loading = this.$vs.loading({
                type:'square',
                text:'... در حال دریافت اطلاعات'
            })
            this.countPerState(this.start_date, this.end_date)
            this.countPerAgentAndState(this.start_date, this.end_date)
            this.countPerStateAndType(this.start_date, this.end_date)
            if(this.agentChartData.datasets.length != 0){
                setTimeout(() => {
                    loading.close()
                }, 1500);
            }else{
                setTimeout(() => {
                    loading.close()
                }, 2000);
            }
        },
        countPerState(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_state/', {params:params}).
            then(
                response=>{
                    if(response.status == 200 ){
                        this.reqStatusChartData = {}
                        let data = []
                        let labels = []
                        response.data.results.forEach(item =>{
                            labels.push(JSON.parse(JSON.stringify((item.token_state_title))))
                            data.push(JSON.parse(JSON.stringify(item.count)))
                        })
                        this.reqStatusChartData = {
                            //Data to be represented on x-axis
                            labels:labels,
                            datasets: [
                                {
                                    label:'',
                                    backgroundColor: '#b981d9',
                                    borderWidth: 1,
                                    //Data to be represented on y-axis
                                    data:data
                                }
                            ]
                        }
                    }
                }
            ).catch(error=>{
                if(error.response.status == 401){
                    auth.logout()
                }
            })
        },
        countPerAgentAndState(start_date, end_date){
            let params={
                start_date:start_date, 
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_agent_and_state/', {params:params}).
            then(
                response =>{
                    if(response.status == 200){
                        // let current_agent = response.data.current_agent
                        this.agentChartData={
                            labels: this.statuses,
                            datasets:[
                                {
                                    label: this.agents[0],
                                    backgroundColor: this.statusColors[0],
                                    data: [response.data.results[0].verify,response.data.results[0].created,response.data.results[0].expired,response.data.results[0].verify_expired,response.data.results[0].image_recived,response.data.results[0].checked,response.data.results[0].rejected],
                                },
                                {
                                    label: this.agents[1],
                                    backgroundColor: this.statusColors[1],
                                    data: [response.data.results[1].verify,response.data.results[1].created,response.data.results[1].expired,response.data.results[1].verify_expired,response.data.results[1].image_recived,response.data.results[1].checked,response.data.results[1].rejected],
                                },
                                {
                                    label: this.agents[2],
                                    backgroundColor: this.statusColors[2],
                                    data: [response.data.results[2].verify,response.data.results[2].created,response.data.results[2].expired,response.data.results[2].verify_expired,response.data.results[2].image_recived,response.data.results[2].checked,response.data.results[2].rejected],
                                },
                                {
                                    label: this.agents[3],
                                    backgroundColor: this.statusColors[3],
                                    data: [response.data.results[3].verify,response.data.results[3].created,response.data.results[3].expired,response.data.results[3].verify_expired,response.data.results[3].image_recived,response.data.results[3].checked,response.data.results[3].rejected],
                                },
                                {
                                    label: this.agents[4],
                                    backgroundColor: this.statusColors[4],
                                    data: [response.data.results[4].verify,response.data.results[4].created,response.data.results[4].expired,response.data.results[4].verify_expired,response.data.results[4].image_recived,response.data.results[4].checked,response.data.results[4].rejected],
                                },
                                {
                                    label: this.agents[5],
                                    backgroundColor: this.statusColors[5],
                                    data: [response.data.results[5].verify,response.data.results[5].created,response.data.results[5].expired,response.data.results[5].verify_expired,response.data.results[5].image_recived,response.data.results[5].checked,response.data.results[5].rejected],
                                },
                                {
                                    label: this.agents[6],
                                    backgroundColor: this.statusColors[6],
                                    data: [response.data.results[6].verify,response.data.results[6].created,response.data.results[6].expired,response.data.results[6].verify_expired,response.data.results[6].image_recived,response.data.results[6].checked,response.data.results[6].rejected],
                                },
                            ],
                        }
                        // this.agentChartData.datasets[current_agent - 1].label = this.agents[current_agent-1]
                    }
                }
            ).catch(error=>{})
        },
        countPerStateAndType(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_state_and_type/', {params:params}).
            then(
                response=>{
                    if(response.status == 200){
                        this.reqTypeChartData = {
                            labels: this.statuses,
                            datasets: [
                                {
                                    label: 'ثبت نام',
                                    backgroundColor: this.typeColors[0],
                                    data: [response.data.results[0].register,response.data.results[1].register,response.data.results[2].register,response.data.results[3].register,response.data.results[4].register,response.data.results[5].register,response.data.results[6].register],
                                },
                                {
                                    label: 'ویرایش',
                                    backgroundColor: this.typeColors[1],
                                    data: [response.data.results[0].edit,response.data.results[1].edit,response.data.results[2].edit,response.data.results[3].edit,response.data.results[4].edit,response.data.results[5].edit,response.data.results[6].edit],
                                },
                            ]
                        }
                    }
                }
            ).catch(error=>{})
        },
        getAgentList(){
            instance.get('/api/agent_list/').then(
                response =>{
                    if(response.status == 200 ){
                        response.data.forEach(item =>{
                            this.agents.push(item.name)
                        })
                    }
                }
            ).catch(error => {})
        },
        getStates(){
            instance.get('/api/state_list/').then(
                response=>{
                    if(response.status == 200 ){
                        response.data.forEach(item =>{
                            this.statuses.push(item.title)
                        })
                    }
                }
            ).catch(error=>{})
        },
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
    },
    mounted(){
        this.getAgentList()
        this.getStates()
        let today = new Date()
        let yesterday = today.setDate(today.getDate()-2)
        let yesterday_jalali = this.convert_to_jalali(yesterday)
        this.start_date = yesterday_jalali
        this.end_date = yesterday_jalali
        this.applyFilter()
    }
}
</script>
<style scoped>
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../../../src/assets/font/IRANSansWeb.woff') format('woff');
}
.card{
    display: flex;
    border-radius: 1rem;
    box-shadow: 0px 4px 6px 0px #bbbbbb;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.font{
    font-family: 'IranSans';
}
.chart-height{
    height: 270px !important;
}
.datepicker{
    border-radius: 0.5rem;
    box-shadow: 0x 2px 5px 1px #bbbbbb !important;
}
</style>