import axios from 'axios';
const instance = axios.create(
    {
        baseURL:'https://tahavoly.ir'
    }
)

var auth = {

    logout(){
        console.log('logout')
        instance.get('/logout/').
        then(
            response=>{
                if(response.status == 200 ){
                    localStorage.clear()
                    window.location = '/login'
                }
            }
        )
    }

}


export default auth;