<template>
  <div dir="rtl" class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <!-- <notifications></notifications> -->
    
    <div class="main-panel" dir="rtl">
        <assessor-nav-bar></assessor-nav-bar>
        <dashboard-content></dashboard-content>

      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
    
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import AssessorNavBar from "./AssessorNavBar.vue";
import DashboardContent from "./Content.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    AssessorNavBar
},
  data() {
    // return {
    //   sidebarBackground: "green",
    //   sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg")
    // };
  }
};
</script>
<style scoped>
.main-panel{
  width: 100% !important;
}
</style>
