<template>
  <div dir="rtl"
    class="sidebar font"
    :data-color="sidebarItemColor"
    :data-image="sidebarBackgroundImage"
    :style="sidebarStyle"
  >
    <div class="logo">
      <!-- <a href="#" class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="imgLogo" alt="" />
        </div>
      </a>  -->

      <router-link to="/" class="text-white m-auto"> 
        <img src="../../assets/img/logo-mafa.png" width="110" height="60" alt="">
      </router-link>
      <!-- <a
        href="/home"
        class="simple-text logo-normal font text-center text-wrap mx-2"
      >
        {{ title }}
      </a> -->
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink
  },
  props: {
    title: {
      type: String,
      default: "مدیریت یکپارچه احراز هویت برخط ثنا"
    },
    sidebarBackgroundImage: {
      type: String,
      // default: require("@/assets/img/sidebar-2.jpg")
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/fidad-logo.png")
    },
    sidebarItemColor: {
      type: String,
      default: "#145da1",
      validator: value => {
        let acceptedValues = ["", "purple", "blue", "green", "orange", "red"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`
      };
    }
  }
};
</script>
<style scoped>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
.font{
  font-family: 'IranSans';
  font-size: 16px !important;
}
.text-wrap{
  white-space: initial !important;
}
.text-white{
  color: #145da1 !important;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
}
.wrapper .sidebar:after{
  background-color: rgb(248, 248, 248) !important;
}
.logo{
  display: flex;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
</style>
