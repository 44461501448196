import { render, staticRenderFns } from "./TopNavbar.vue?vue&type=template&id=863b80f8&scoped=true&"
import script from "./TopNavbar.vue?vue&type=script&lang=js&"
export * from "./TopNavbar.vue?vue&type=script&lang=js&"
import style0 from "./TopNavbar.vue?vue&type=style&index=0&id=863b80f8&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "863b80f8",
  null
  
)

export default component.exports