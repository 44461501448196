<script>
  //Importing Bar class from the vue-chartjs
  import { Bar , mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins
  //Exporting this so it can be used in other components
  export default {
    name: 'draw-bar',
    extends: Bar,
    mixins:[reactiveProp],
    props:{
      options:{
        type: Object,
      }
    },
    mounted () {
      //renderChart function renders the chart with the chartData and options object.
      this.renderChart(this.chartData, this.options)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
