<template>
    <div class="bg-login-blue font">
        <div class="row">
            <div class="col-12">
                <img src="../assets/img/logo-mafa.png" class="d-block mx-auto mt-lg-4 filter-invert logo-size" alt="">
            </div>
        </div>
        <h5 class="text-center font mt-5 text-gray">سامانه مدیریت یکپارچه احراز هویت برخط ثنا</h5>
        <div class="d-flex mx-auto my-40 login-card-size mt-5 pt-3" dir="rtl">
            <div class="d-flex login-card w-100 border-radius login-card-shadow">
                <div class="w-100">
                    <label>
                        <input @keyup.enter="login" type="text" id="username" v-model="username" class="username p-2 w-100 text-right" dir="ltr" placeholder=" ">
                        <span>نام کاربری</span>
                    </label>
                    <div class="input-container w-100 mt-45 pb-0">
                        <label class="w-100">
                            <input @keyup.enter="login" type="password" v-model="password" id="password" class="password p-2 w-100 text-right h-100" dir="ltr" placeholder=" ">
                            <span>رمز عبور</span>
                        </label>
                        <i v-if="hide"  @click="showPassword()" class="fas fa-eye-slash eye-position"></i>
                        <i v-if="!hide" @click="showPassword()"  class="fas fa-eye eye-position"></i>
                    </div>
                    <button @click="login" class="btn btn-success w-100 text-center mt-5 py-2">ورود</button>
                    <h3 class="text-center text-bolder font mt-4 text-white w-100">قوه قضائیه هوشمند</h3>
                    <h3 class="text-center text-bolder font text-white w-100"> در تراز انقلاب اسلامی</h3>
                </div>
            </div>
        </div>
        <div class="text-bottom">طراحی شده توسط شرکت فناوری های تحول یار شریف</div>
    </div>
</template>
<script>
import axios from 'axios'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const instance = axios.create({
    method:'post',
    baseURL:'https://tahavoly.ir',
    headers:{
        'xsrfHeaderName': 'X-CSRFToken',
        'withCredentials': true
    }
})

export default {
    data(){
        return {
            hide:true,
            username:'',
            password:'',
            error_message:''
        }
    },
    methods:{
        showPassword(){
            let password = document.getElementById('password')
            if(this.hide){
                password.type = 'text'
                this.hide = false
            }else{                                                                                          
                password.type = 'password'
                this.hide = true
            }
        },
        login(){
            let loginform = new FormData()
            loginform.append('username', this.username)
            loginform.append('password', this.password)

            instance.post('user/verify/', loginform).
            then(
                response =>{
                    if (response.status == 200 && 'access' in response.data.data){
                        localStorage.setItem('user_id', response.data.data.user.id)
                        localStorage.setItem('is_superuser', response.data.data.user.is_superuser)
                        localStorage.setItem('is_agent', response.data.data.user.is_agent)
                        localStorage.setItem('is_assessor', response.data.data.user.is_assessor)
                        localStorage.setItem('is_staff', response.data.data.user.is_staff)
                        let path=''
                        this.showNotif('ورود موفق','','success')


                        if(localStorage.getItem('is_agent') == 'true' || localStorage.getItem('is_superuser') == 'true'){
                            // if (this.$route.query.next != undefined){
                            //     path= this.$route.query.next
                            //     this.$router.push(path)
                            // }
                            // else
                            this.$router.push('/')
                        }
                        if (localStorage.getItem('is_assessor') == 'true'){
                            this.$router.push('/assessment')
                        }
                    }
                }
            ).catch(
                error=>{
                    if (error.response.status == 401){
                        this.username=''
                        this.password=''
                        this.showNotif('نام کاربری یا رمز عبور اشتباه است','','danger')
                    }
                }
            )
        },
        showNotif(title, text, color){
            this.$vs.notification({
                title:title,
                text:text,
                border:color,
                position:'top-center'
            })
        }
    },
} 
</script>
<style lang="scss" scoped>
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../assets/font/IRANSansWeb.woff') format('woff');
}

.font{
    font-family: 'Iransans';
}
.bg-login-blue{
    background-image: linear-gradient(131deg,rgb(27,65,135), rgb(33,86,179)) !important;
    position: fixed;
    right: 0px;
    left: 0px;
    bottom: 0px;
    top: 0px;
}
.login-card{
    background-color: transparent;
}
.login-card-size{
    height: 400px;
    width: 350px;
}
.logo-size{
    height: 190px;
}
.filter-invert{
    filter: invert(1);
}
.border-radius{
    border-radius: 0.7rem !important;
}
.username{
    border: 0;
    border-radius: 0.5rem;
    background-color: rgba($color: #fff, $alpha: 0.75);
    appearance: none;
    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-right-style: hidden;
    font-size: 18px !important;
    height:40px

}
.username:focus{
    border:blue !important;
}
.password{
    border: 0;
    appearance: none;
    border-radius: 0.5rem;
    background: transparent;
    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-right-style: hidden;
    outline-style: hidden;
    font-size: 18px !important;
    height:40px !important;
    
}
.password:focus{
    outline: none !important;
}
.input-container{
    display: flex;
    width: 100% !important;
    height: 40px !important;
    background-color:rgba($color: #fff, $alpha: 0.75);
    border-radius: 0.5rem;
    }
.eye-position{
    position: relative;
    top: 13px;
    left: 12px;
}
.btn{
    border-radius: 0.5rem !important;
}
.btn-success{
    // background-image: linear-gradient(90deg, #3eb149, #138ed2);
    background-color: #2da239;
    border: transparent !important;
}

.btn:focus{
    border: 0px;
}

label {
  position:relative;
  display:flex;
}
  
span {
    font-size: 18px !important;
    padding-right:10px;  
    padding-bottom: 8px;
    pointer-events: none;
    position:absolute;
    right:0;
    top:6px;
    transition: 0.4s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.5;
}

input:focus + span, input:not(:placeholder-shown) + span {
    right: -30px;
    color: white;
    opacity:1;
    font-weight: bold;
    transform: translateY(-100%) translateX(-30px);
}

/* For IE Browsers*/
input:focus + span, input:not(:-ms-input-placeholder) + span {
    right: -28px;
    color: white;
    opacity:1;
    font-weight: bold;
    transform: translateY(-100%) translateX(-30px);
}
.text-gray{
    color: #c2c2c2 !important;
    font-weight: bold;
}
.text-bottom{
    position: fixed;
    bottom: 20px;
    text-align: center;
    width: 100%;
    color: whitesmoke;
    font-size: 12px;
}
.mt-45{
    margin-top: 2.2rem;
}
.text-bolder{
    font-weight: 700;
}
</style>