<template>
    <div class="row font">
        <div class="col-12">
            <div class="sticky mt-3 d-flex">
            <ul class="list-none text-right mx-auto" dir="rtl">
                <li class="list-horizontal pl-5 text-nowrap">تعداد کل توکن ها: <span class="text-danger text-bold">{{total_count}}</span></li>
                <li class="list-horizontal pl-5 text-nowrap">تعداد تایید شده و رد شده: <span class="text-danger text-bold">{{verify_count}}</span></li>
            </ul>
        </div>
            <div class="row mt-2 pr-3 bg-custom py-2 font">
                <div class="col-lg-2">
                    <!-- <label >از تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="start_date" id="start_date" placeholder="از تاریخ">
                    <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <!-- <label >تا تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                    <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <button class="btn btn-primary float-right py-1" @click="applyFilter()">دریافت</button>
                </div>
            </div>
            <!-- <div class="row px-3">
                <div class="card">
                    <div id="trendbar" ref="trendbar"></div>
                </div>
            </div> -->
            <div class="row px-3">
                <div class="col-lg-4 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">تعداد خطا <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <div dir="ltr" class="solid"  id="chartdiv" ref="chartdiv"></div>
                        <!-- <bar-chart :chartData="errorChartData" :options="options" style="height:270px !important"></bar-chart> -->
                    </div>
                </div>
                <div class="col-lg-8 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font" dir="ltr">درخواست به تفکیک کارگزار</label>
                            <div id="column" ref="columnchart" dir="ltr" class="text-left"></div> 
                        <!-- <bar-chart v-if="agentChartData.datasets[0].data.length != 0" :chartData="agentChartData" :options="options" style="height:350px !important"></bar-chart> -->
                    </div>
                </div>
            </div>
            <div class="row mb-2 px-3">
                <div class="col-lg-4 p-2">
                    <div class="card">
                        <label class="text-right font">وضعیت درخواست</label>
                            <div id="doughnut" ref="statusdoughnutchart" dir="ltr" class="text-left"></div>
                        <!-- <doughnut-chart v-if="reqStatusChartData.datasets[0].data.length != 0" :chartData="reqStatusChartData" :options="pieOptions" :width="350" :height="250"></doughnut-chart> -->
                    </div>
                </div>
                <div class="col-lg-4 p-2">
                    <div class="card">
                        <label class="text-right font">نوع درخواست</label>
                            <div id="doughnut" ref="typedoughnutchart" dir="ltr" class="text-left"></div>
                        <!-- <doughnut-chart v-if="reqTypeChartData.datasets[0].data.length != 0" :chartData="reqTypeChartData" :options="pieOptions" :width="350" :height="250"></doughnut-chart> -->
                    </div>
                </div>
                <div class="col-lg-4 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">درخواست های ارزیابی - داشبورد</label>
                        <bar-chart :chartData="dashboardChartData" :options="options" style="height:300px !important"></bar-chart>
                    </div>
                </div>
            </div>
            <div class="row px-3 mt-3 mb-0" v-if="isAgent">
                <div class="col-12">
                    <h6 class="font text-dark-blue float-right text-bold">گزارش های مبتنی بر شاخص کلیدی عملکرد</h6>
                </div>
            </div>
            <div class="row px-3" v-if="isAgent">
                <div class="col-lg-6 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">وضعیت درخواست برحسب کارگزار</label>
                        <bar-chart :chartData="agentStateChartData" :options="AgentChartsoptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-6 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">نوع درخواست برحسب کارگزار</label>
                        <bar-chart :chartData="agentTypeChartData" :options="AgentChartsoptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
            </div>
            <div class="row px-3" v-if="isAgent">
                <div class="col-lg-2"></div>
                <div class="col-lg-8 p-2 pt-1">
                    <div class="card">
                        <label class="text-right font">خطا برحسب کارگزار  <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData="errorChartData" :options="AgentChartsoptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'jalali-moment';
import barChart from '../../components/barChart.vue';
import pieChart from '../../components/pieChart.vue';
import doughnutChart from '../../components/doughnutChart.vue'
import columnChart from '../../components/columnChart.vue'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import auth from '../../services/authService.js';

am4core.useTheme(am4themes_animated);
const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})
export default {
    components:{
    barChart,
    pieChart,
    doughnutChart,
    columnChart,
},
    data(){
        let typeColors =['#21abc9','#f95d6a']
        let errors=['201','202','203','204','205','206','207','208']
        let statusColors= ['#4fc1e8','#a0d568','#6c757d','#ac92eb','#ffce54','#ed5564','#488f31']
        return{
            load:false,
            start_date:'',
            end_date:'',
            agents:[],
            statuses:[],
            isAgent:false,
            total_count:0,
            verify_count:0,
            show_agent_chart:false,
            types:['ثبت نام','ویرایش'],
            agentColors:['#4fc1e8','#a0d568','#ac92eb','#ffce54','#ed5564','#488f31','#6c757d'],
            statusColors: ['#4fc1e8','#a0d568','#6c757d','#ac92eb','#ffce54','#ed5564','#488f31'],
            columnData:[],
            statusDoughnutChartData:[],
            typeDoughnutChartData:[],
            AgentChartsoptions: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:true
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                },
                legend:{
                    labels:{
                        fontFamily:'IranSans'
                    },
                    position:'bottom'
                },
                tooltips:{
                    titleFontFamily:'IranSans',
                    bodyFontFamily:'IranSans'
                },
                responsive: true,
                maintainAspectRatio: false,
                
            },

            options: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }]
                },
                legend:{
                    display:false
                },
                responsive: true,
                maintainAspectRatio: false,
            },


            errorChartData:{
                labels: errors,
                datasets: [
                    {
                        label:'',
                        backgroundColor: '#00bfa0',
                        data: [1084,498,429,646,4,172,3148,45],
                    },
                ]
            },
            agentChartData:{
                labels: [],
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#4770b3',
                        data: [],
                    },
                ]
            },

            pieOptions:{
                type:'doughnut',
                maintainAspectRatio:false,
                responsive:false,
                legend:{
                    labels:{
                        fontFamily:'IranSans'
                    },
                    position:'top'
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                        //get the concerned dataset
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        //calculate the total of this data set
                        var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                            return previousValue + currentValue;
                        });
                        //get the current items value
                        var currentValue = dataset.data[tooltipItem.index];
                        //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                        var percentage = Math.floor(((currentValue/total) * 100)+0.5);

                        return percentage + "%";
                        }
                    }
                } 
            },

            reqStatusChartData:{
                //Data to be represented on x-axis
                labels:[],
                datasets: [
                    {
                    backgroundColor: statusColors,
                    borderWidth: 1,
                    //Data to be represented on y-axis
                    data:[]
                    }
                ]
            },

            reqTypeChartData:{
            //Data to be represented on x-axis
                labels:[],
                datasets: [
                    {
                    backgroundColor: typeColors,
                    borderWidth: 1,
                    //Data to be represented on y-axis
                    data:[]
                    }
                ]
            },

            dashboardChartData:{
                labels: this.agents,
                datasets: []
            },

            agentTypeChartData:{
                labels: [],
                datasets: []
            },

            agentStateChartData:{
                labels: [],
                datasets: []
            },

        }
    },
    methods:{
        getStateList(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_state/', {params:params}).
            then(
                response=>{
                    if(response.status == 200 ){
                        this.total_count = 0
                        this.verify_count = 0
                        this.statusDoughnutChartData = response.data.results
                        this.statusDoughnutChart()
                        this.statusDoughnutChartData.forEach(item =>{
                            if(item.token_state_title == 'احراز شده' || item.token_state_title == 'رد شده'){
                                this.verify_count += item.count
                            }else{
                                this.total_count += item.count
                            }
                        })
                        this.total_count += this.verify_count;
                    }
                }
            )
        },
        getValidation(start_date, end_date){
            let params={
                start_date:start_date, 
                end_date:end_date
            }
            instance.get('/api/validation/', {params:params}).
            then(
                response =>{
                    if(response.status == 200){
                        let false_confirm = []
                        let false_reject = []
                        let suspicious = []
                        response.data.results.forEach((item, index)=>{
                            false_confirm.push(item[index+1].false_confirmation)
                            false_reject.push(item[index+1].false_rejection)
                            suspicious.push(item[index+1].suspicious)
                        })
                        this.dashboardChartData = {
                            labels:this.agents,
                            datasets:[
                                {
                                    label: 'تایید کاذب',
                                    backgroundColor: '#ffce54',
                                    data: false_confirm,
                                },
                                {
                                    label: 'رد کاذب',
                                    backgroundColor: '#ed5564',
                                    data: false_reject,
                                },
                                {
                                    label: 'مشکوک',
                                    backgroundColor: '#4770b3',
                                    data: suspicious,
                                }
                            ]
                        }
                    }
                }
            ).catch(
            )
        },
        countPerAgent(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('api/tokens/count_per_agent/', {params:params}).
            then(
                response =>{
                    if(response.status==200){
                        this.columnData = response.data.results
                        this.columnData.forEach(
                            (item, index) =>{
                                if(item.agent_logo != undefined)
                                    item.agent_logo = 'media/'+ item.agent_logo
                                if(item.agent_name == null){
                                    item.agent_name = index
                                }
                            }
                        )
                        this.columnChart()
                    }
                }
            ).catch(
                error => {
                    if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
        },
        countPerType(start_date, end_date){
            let params = {
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_type/', {params:params}).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.typeDoughnutChartData = response.data.results
                        this.typeDoughnutChartData.forEach(item => {
                            if(item.token_type == "1")
                                item.token_type='ثبت نام'
                            else
                                item.token_type='ویرایش'
                        })
                        this.typeDoughnutChart()
                    }
                }
            ).catch(error => {})
        },
        countPerAgentAndState(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_agent_and_state/', {params:params}).
            then(
                response =>{
                    if(response.status == 200){
                        let current_agent = response.data.current_agent
                        this.agentStateChartData={
                            labels: this.statuses,
                            datasets:[
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[0],
                                    data: [response.data.results[0].verify,response.data.results[0].created,response.data.results[0].expired,response.data.results[0].verify_expired,response.data.results[0].image_recived,response.data.results[0].checked,response.data.results[0].rejected],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[1],
                                    data: [response.data.results[1].verify,response.data.results[1].created,response.data.results[1].expired,response.data.results[1].verify_expired,response.data.results[1].image_recived,response.data.results[1].checked,response.data.results[1].rejected],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[2],
                                    data: [response.data.results[2].verify,response.data.results[2].created,response.data.results[2].expired,response.data.results[2].verify_expired,response.data.results[2].image_recived,response.data.results[2].checked,response.data.results[2].rejected],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[3],
                                    data: [response.data.results[3].verify,response.data.results[3].created,response.data.results[3].expired,response.data.results[3].verify_expired,response.data.results[3].image_recived,response.data.results[3].checked,response.data.results[3].rejected],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[4],
                                    data: [response.data.results[4].verify,response.data.results[4].created,response.data.results[4].expired,response.data.results[4].verify_expired,response.data.results[4].image_recived,response.data.results[4].checked,response.data.results[4].rejected],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[5],
                                    data: [response.data.results[5].verify,response.data.results[5].created,response.data.results[5].expired,response.data.results[5].verify_expired,response.data.results[5].image_recived,response.data.results[5].checked,response.data.results[5].rejected],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[6],
                                    data: [response.data.results[6].verify,response.data.results[6].created,response.data.results[6].expired,response.data.results[6].verify_expired,response.data.results[6].image_recived,response.data.results[6].checked,response.data.results[6].rejected],
                                },
                            ],
                        }
                        if(current_agent!=undefined)
                            this.agentStateChartData.datasets[current_agent-1].label = this.agents[0]
                    }
                }
            ).catch(error=>{})
        },
        countPerAgentAndType(start_date, end_date){
            let params={
                start_date:start_date, 
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_agent_and_type/', {params:params}).
            then(
                response =>{
                    if(response.status == 200 ){
                        let current_agent = response.data.current_agent
                        this.agentTypeChartData = {
                            labels: this.types,
                            datasets: [
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[0],
                                    data: [response.data.results[0].register,response.data.results[0].edit],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[1],
                                    data: [response.data.results[1].register,response.data.results[1].edit],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[2],
                                    data: [response.data.results[2].register,response.data.results[2].edit],
                                },{
                                    label: '',
                                    backgroundColor: this.agentColors[3],
                                    data: [response.data.results[3].register,response.data.results[3].edit],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[4],
                                    data: [response.data.results[4].register,response.data.results[4].edit],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[5],
                                    data: [response.data.results[5].register,response.data.results[5].edit],
                                },
                                {
                                    label: '',
                                    backgroundColor: this.agentColors[6],
                                    data: [response.data.results[6].register,response.data.results[6].edit],
                                },
                            ]
                        }
                        if(current_agent != undefined)
                            this.agentTypeChartData.datasets[current_agent-1].label = this.agents[0]
                    }
                }
            ).catch(error => {})
        },
        applyFilter(){
            const loading = this.$vs.loading({
                type:'square',
                text:'... در حال دریافت اطلاعات'
            })
            let sdate = moment.from(this.start_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            let edate= moment.from(this.end_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            this.countPerAgent(sdate, edate)
            this.getStateList(sdate, edate)
            this.countPerType(sdate, edate)
            this.getValidation(sdate, edate)
            this.countPerAgentAndState(sdate, edate)
            this.countPerAgentAndType(sdate, edate)
            if(this.reqStatusChartData.datasets[0].data.length != 0){
                setTimeout(() => {
                    loading.close()
                }, 1500);
            }else{
                setTimeout(() => {
                    loading.close()
                }, 2000);
            }
        },
        // drawTrendBar(){
        //     let chart = am4core.create(this.$refs.trendbar, am4charts.XYChart)
        //     chart.padding(40, 40, 40, 40);
        //     chart.numberFormatter.bigNumberPrefixes = [
        //         { "number": 1e+3, "suffix": "K" },
        //         { "number": 1e+6, "suffix": "M" },
        //         { "number": 1e+9, "suffix": "B" }
        //     ];
        //     var label = chart.plotContainer.createChild(am4core.Label);
        //     label.x = am4core.percent(100);
        //     label.y = am4core.percent(100);
        //     label.horizontalCenter = "right";
        //     label.verticalCenter = "middle";
        //     label.dx = -15;
        //     //change font size
        //     label.fontSize = this.label_size;
        //     var stepDuration = 10 * 1000;

        //     if(this.vertical_view){
        //         var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        //     }else{
        //         var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        //     }
        //     categoryAxis.renderer.grid.template.location = 0;
        //     categoryAxis.dataFields.category = "label";
        //     // categoryAxis.dataFields.category = "network";
        //     categoryAxis.renderer.minGridDistance = 1;
        //     categoryAxis.renderer.inversed = true;
        //     categoryAxis.renderer.grid.template.disabled = true;
        //     categoryAxis.fontSize = this.font_size;


        //     if(this.vertical_view){
        //         var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //     }else{
        //         var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        //     }
        //     valueAxis.min = 0;
        //     valueAxis.rangeChangeEasing = am4core.ease.linear;
        //     valueAxis.rangeChangeDuration = stepDuration;
        //     valueAxis.extraMax = 0.1;
        //     valueAxis.fontSize = this.font_size;
            

        //     var series = chart.series.push(new am4charts.ColumnSeries());
        //     if(this.vertical_view){
        //         series.dataFields.categoryX = "label";
        //         series.dataFields.valueY = "value";
        //         // series.dataFields.categoryX = "network";
        //         // series.dataFields.valueY = "MAU";
        //         series.tooltipText = "{valueY.value}"
        //         if(this.color_type == "gradiant"){
        //         series.heatRules.push({ target: series.columns.template, property: "fill", dataField: "valueY", min: am4core.color(this.start_color), max:am4core.color(this.end_color) });
        //         }
        //     }else{
        //         series.dataFields.categoryY = "label";
        //         series.dataFields.valueX = "value";
        //         // series.dataFields.categoryY = "network";
        //         // series.dataFields.valueX = "MAU";
        //         series.tooltipText = "{valueX.value}";
        //         if(this.color_type == "gradiant")
        //         series.heatRules.push({ target: series.columns.template, property: "fill", dataField: "valueX", min: am4core.color(this.start_color) , max: am4core.color(this.end_color) });
        //     }
        //     series.columns.template.strokeOpacity = 0;
        //     //change radius
        //     series.columns.template.column.cornerRadiusTopRight = this.right_radius;
        //     if(this.vertical_view){
        //         series.columns.template.column.cornerRadiusTopLeft = this.left_radius;
        //     }else{
        //         series.columns.template.column.cornerRadiusBottomRight = this.left_radius;
        //     }
        //     series.columns.template.column.propertyFields.fill = 'color'
        //     //change duration
        //     series.interpolationDuration = stepDuration;
        //     series.interpolationEasing = am4core.ease.linear;

        //     var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        //     labelBullet.label.horizontalCenter = "right";
        //     // labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
        //     labelBullet.label.textAlign = "end";
        //     labelBullet.label.dx = -10;
        //     labelBullet.fontSize = this.font_size

        //     var bullet = series.bullets.push(new am4charts.CircleBullet());
        //     bullet.circle.radius = this.radius;
        //     if(this.vertical_view){
        //         bullet.valign = "top";
        //         bullet.align = "center";
        //     }else{
        //         bullet.valign = "center";
        //         bullet.align = "right";
        //     }
        //     var image = bullet.createChild(am4core.Image);
        //     image.horizontalCenter = "middle";
        //     image.verticalCenter = "middle";
        //     image.propertyFields.href = "image";
        //     image.width = +this.image_width;
        //     image.height = +this.image_height;
        //     image.adapter.add("mask", function (mask, target) {
        //         var circleBullet = target.parent;
        //     return circleBullet.circle;
        //     })

        //     chart.exporting.menu = new am4core.ExportMenu();
        //     chart.exporting.menu.align = "left";
        //     chart.exporting.menu.verticalAlign = "top";
            
        //     // image.tooltipText = series.columns.template.tooltipText;
        //     // image.propertyFields.fill = "color";
        //     // image.filters.push(new am4core.DropShadowFilter());

        
        //     // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        //     // series.columns.template.adapter.add("fill", function(fill, target){
        //     //   return chart.colors.getIndex(target.dataItem.index);
        //     // });
            
        //     chart.zoomOutButton.disabled = true;
        //     // var firstTime = this.times[0];
        //     // var lastTime = this.times[this.times.length - 1];
        //     // var chartData = this.chartData;
        //     // var year = 2003;
        //     var year = this.times[0];
        //     label.text = year.toString();
        //     var interval;
        //     },
        drawSolidCart(){
            let chart = am4core.create(this.$refs.chartdiv, am4charts.RadarChart);
            // chart.height = '250'
            chart.data = [{
                "category": "۲۰۱",
                "value": 1084,
                "full": 3148
                }, {
                "category": "۲۰۲",
                "value": 498,
                "full": 3148
                }, {
                "category": "۲۰۳",
                "value": 429,
                "full": 3148
                }, {
                "category": "۲۰۴ ",
                "value": 646,
                "full": 3148
                }, {
                "category": "۲۰۵",
                "value": 4,
                "full": 3148
                }, {
                "category": "۲۰۶",
                "value": 172,
                "full": 3148
                }, {
                "category": "۲۰۷",
                "value": 3148,
                "full": 3148
                }, {
                "category": "۲۰۸",
                "value": 45,
                "full": 3148
                }
                ];

                // Make chart not full circle
                chart.startAngle = -90;
                chart.endAngle = 180;
                chart.innerRadius = am4core.percent(20);

                // Set number format
                // chart.numberFormatter.numberFormat = "#.#'%'";

                // Create axes
                let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "category";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.grid.template.strokeOpacity = 0;
                categoryAxis.renderer.labels.template.horizontalCenter = "right";
                categoryAxis.renderer.labels.template.fontWeight = 300;
                categoryAxis.renderer.labels.template.adapter.add("fill", function(fill, target) {
                return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
                });
                categoryAxis.renderer.minGridDistance = 10;

                let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                valueAxis.renderer.grid.template.strokeOpacity = 0;
                valueAxis.min = 0;
                valueAxis.max = 3148;
                valueAxis.strictMinMax = true;

                // Create series
                let series1 = chart.series.push(new am4charts.RadarColumnSeries());
                series1.dataFields.valueX = "full";
                series1.dataFields.categoryY = "category";
                series1.clustered = false;
                series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
                series1.columns.template.fillOpacity = 0.08;
                series1.columns.template.cornerRadiusTopLeft = 20;
                series1.columns.template.strokeWidth = 0;
                series1.columns.template.radarColumn.cornerRadius = 20;

                let series2 = chart.series.push(new am4charts.RadarColumnSeries());
                series2.dataFields.valueX = "value";
                series2.dataFields.categoryY = "category";
                series2.clustered = false;
                series2.columns.template.strokeWidth = 0;
                series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
                series2.columns.template.radarColumn.cornerRadius = 20;

                series2.columns.template.adapter.add("fill", function(fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
            chart.cursor = new am4charts.RadarCursor();
        },
        columnChart(){
            let chart = am4core.create(this.$refs.columnchart, am4charts.XYChart);
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

            chart.paddingBottom = 30;

            chart.data = this.columnData
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "agent_name";
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.minGridDistance = 10;
            categoryAxis.renderer.labels.template.dy = 35;
            categoryAxis.renderer.tooltip.dy = 35;

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.fillOpacity = 0.3;
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.min = 0;
            valueAxis.cursorTooltipEnabled = false;
            valueAxis.renderer.baseGrid.strokeOpacity = 0;

            let series = chart.series.push(new am4charts.ColumnSeries);
            series.dataFields.valueY = "token_count";
            series.dataFields.categoryX = "agent_name";
            series.tooltipText = "{valueY.value}";
            series.tooltip.pointerOrientation = "vertical";
            series.tooltip.dy = - 6;
            series.columnsContainer.zIndex = 100;

            let columnTemplate = series.columns.template;
            columnTemplate.width = am4core.percent(50);
            columnTemplate.maxWidth = 40;
            columnTemplate.column.cornerRadius(10, 10, 10, 10);
            columnTemplate.strokeOpacity = 0;

            series.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueY", min: am4core.color("#00bbe0"), max: am4core.color("#154d81") });
            series.mainContainer.mask = undefined;

            let cursor = new am4charts.XYCursor();
            chart.cursor = cursor;
            cursor.lineX.disabled = true;
            cursor.lineY.disabled = true;
            cursor.behavior = "none";

            let bullet = columnTemplate.createChild(am4charts.CircleBullet);
            bullet.circle.radius = 30;
            bullet.valign = "bottom";
            bullet.align = "center";
            bullet.isMeasured = true;
            bullet.mouseEnabled = false;
            bullet.verticalCenter = "bottom";
            bullet.interactionsEnabled = false;

            let hoverState = bullet.states.create("hover");
            let outlineCircle = bullet.createChild(am4core.Circle);
            outlineCircle.adapter.add("radius", function (radius, target) {
                let circleBullet = target.parent;
                return circleBullet.circle.pixelRadius + 10;
            })

            let image = bullet.createChild(am4core.Image);
            image.width = 60;
            image.height = 60;
            image.horizontalCenter = "middle";
            image.verticalCenter = "middle";
            image.propertyFields.href = "agent_logo";

            image.adapter.add("mask", function (mask, target) {
                let circleBullet = target.parent;
                return circleBullet.circle;
            })
            let previousBullet;
            chart.cursor.events.on("cursorpositionchanged", function (event) {
                let dataItem = series.tooltipDataItem;

                if (dataItem.column) {
                    let bullet = dataItem.column.children.getIndex(1);

                    if (previousBullet && previousBullet != bullet) {
                        previousBullet.isHover = false;
                    }

                    if (previousBullet != bullet) {

                        let hs = bullet.states.getKey("hover");
                        hs.properties.dy = -bullet.parent.pixelHeight + 30;
                        bullet.isHover = true;

                        previousBullet = bullet;
                    }
                }
            })
        },
        statusDoughnutChart(){
            var chart = am4core.create(this.$refs.statusdoughnutchart, am4charts.PieChart);
            // Add and configure Series
            var pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "count";
            pieSeries.dataFields.category = "token_state_title";
            // Let's cut a hole in our Pie chart the size of 30% the radius
            chart.innerRadius = am4core.percent(30);

            // Put a thick white border around each Slice
            pieSeries.slices.template.stroke = am4core.color("#fff");
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.slices.template
            // change the cursor on hover to make it apparent the object can be interacted with
            .cursorOverStyle = [
                {
                "property": "cursor",
                "value": "pointer"
                }
            ];

            // pieSeries.alignLabels = false;
            pieSeries.labels.template.disabled = true
            // pieSeries.labels.template.bent = true;
            // pieSeries.labels.template.radius = 3;
            // pieSeries.labels.template.padding(0,0,0,0);

            // pieSeries.ticks.template.disabled = true;

            // Create a base filter effect (as if it's not there) for the hover to return to
            var shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
            shadow.opacity = 0;

            // Create hover state
            var hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

            // Slightly shift the shadow and make it more prominent on hover
            var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
            hoverShadow.opacity = 0.7;
            hoverShadow.blur = 5;

            // Add a legend
            chart.legend = new am4charts.Legend();
            chart.data = this.statusDoughnutChartData

        },
        typeDoughnutChart(){
            var chart = am4core.create(this.$refs.typedoughnutchart, am4charts.PieChart);
            // Add and configure Series
            var pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "count";
            pieSeries.dataFields.category = "token_type";
            // Let's cut a hole in our Pie chart the size of 30% the radius
            chart.innerRadius = am4core.percent(30);

            // Put a thick white border around each Slice
            pieSeries.slices.template.stroke = am4core.color("#fff");
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.slices.template
            // change the cursor on hover to make it apparent the object can be interacted with
            .cursorOverStyle = [
                {
                "property": "cursor",
                "value": "pointer"
                }
            ];

            // pieSeries.alignLabels = false;
            pieSeries.labels.template.disabled = true
            // pieSeries.labels.template.bent = true;
            // pieSeries.labels.template.radius = 3;
            // pieSeries.labels.template.padding(0,0,0,0);

            // pieSeries.ticks.template.disabled = true;

            // Create a base filter effect (as if it's not there) for the hover to return to
            var shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
            shadow.opacity = 0;

            // Create hover state
            var hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

            // Slightly shift the shadow and make it more prominent on hover
            var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
            hoverShadow.opacity = 0.7;
            hoverShadow.blur = 5;

            // Add a legend
            chart.legend = new am4charts.Legend();
            chart.data = this.typeDoughnutChartData
        },
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
        getAgents(){
            instance.get('/api/agent_list/').then(
                response =>{
                    if(response.status == 200 ){
                        response.data.forEach(item =>{
                            this.agents.push(item.name)
                        })
                    }
                }
            ).catch( error => {})
        },
        getStates(){
            instance.get('/api/state_list/').then(
                response=>{
                    if(response.status == 200 ){
                        response.data.forEach(item =>{
                            this.statuses.push(item.title)
                        })
                    }
                }
            ).catch( error =>{})
        },
    },
    mounted(){
        setTimeout(() => {
            this.isAgent = localStorage.getItem('is_agent') === 'true'
        },1000)
        this.getAgents()
        this.getStates()
        let today = new Date()
        let yesterday = today.setDate(today.getDate()-2)
        let yesterday_jalali = this.convert_to_jalali(yesterday)
        this.start_date = yesterday_jalali
        this.end_date = yesterday_jalali
        this.applyFilter()
        this.drawSolidCart()
        this.columnChart()
    }
}
</script>
<style scoped>
@media only screen and(max-width:450px){
    div.sticky{
        width: 100% !important;
    }
}
@media only screen and(min-width:576px){
    .list-horizontal{
        float: right !important;
    }
}
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../../../src/assets/font/IRANSansWeb.woff') format('woff');
}
.card{
    display: flex;
    border-radius: 1rem;
    box-shadow: 0px 4px 6px 0px #bbbbbb;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.font{
    font-family: 'IranSans';
}
.datepicker{
    border-radius: 0.5rem;
    box-shadow: 0x 2px 5px 1px #bbbbbb !important;
}
/* .bg-custom{
    background-color: #e0e1e2 !important;
} */
.text-dark-blue{
    color: #0e4e8a !important;
    border-bottom: 1px solid #0e4e8a;
}
.text-bold{
    font-weight: bold;
}
.solid{
    height: 100% !important;
}
#column{
    height: 400px;
    width: 100%  !important;

}
#doughnut{
    height: 600px;
}
div.sticky{
    width: 45%;
    margin: auto;
    position: -webkit-sticky;
    position: sticky !important;
    top:0 !important;
    padding-top: 17px !important;
    background-color: #4294ff;
    box-shadow: 1px 3px 8px rgba(60, 60, 60, 0.499);
    color: white;
    font-size: 18px;
    z-index: 500;
}
.list-none{
    list-style: none;
}
.list-horizontal{
    float: right !important;
}
</style>