<template>
    <div class="bg-none px-4 pt-3">
        <div class="row font" dir="rtl">
            <div class="col-lg-5">
                <div class="row pt-4">
                    <div class="col-lg-3 col-4 mt-1 mt-lg-0">
                    <!-- <label >از تاریخ</label> -->
                        <input type="text" v-model="repo_start_date" class="datepicker w-100" id="repo_start_date" placeholder="از تاریخ">
                        <date-picker v-model="repo_start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="repo_start_date"></date-picker>
                    </div>
                    <div class="col-lg-3 col-4 mt-1 mt-lg-0">
                        <!-- <label >تا تاریخ</label> -->
                        <input type="text" v-model="repo_end_date" class="datepicker w-100" id="repo_end_date" placeholder="تا تاریخ" >
                        <date-picker v-model="repo_end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="repo_end_date"></date-picker>
                    </div>
                    <div class="col-lg-3 col-2 mt-1 mt-lg-0">
                        <button class="btn btn-primary float-right py-1" @click="getReports()">دریافت</button>
                    </div>
                </div>
                <table class="table table-responsive-sm text-right table-striped mt-4">
                    <thead>
                        <tr>
                            <th colspan="">از تاریخ</th>
                            <th colspan="">تا تاریخ</th>
                            <th colspan=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in reports" :key="index">
                            <td colspan="">{{item.start_date}}</td>
                            <td colspan="">{{item.end_date}}</td>
                            <td colspan="">
                                <span v-if="item.file == null">در حال آماده سازی فایل</span>
                                <span v-else><a :href="item.file">دریافت فایل</a></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row mt-3 font pb-3" dir="ltr">
                    <div class="col-3 col-lg-3 pl-5">
                        <button class="sarshenas-btn-dark circle float-start" @click="prevPage()">❮</button>
                    </div>
                    <div class="col-5 col-lg-4 text-center text-dark-blue">
                        <span>صفحه ی</span> <span>{{currentPage}}</span> <span>از</span> <span>{{numOfPage}}</span>
                    </div>
                    <div class="col-3 col-lg-3">
                        <button class="sarshenas-btn-dark ml-1 circle float-" @click="nextPage()">❯</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="row pt-4">
                    <div class="col-lg-3 col-4 mt-1 mt-lg-0">
                    <!-- <label >از تاریخ</label> -->
                        <input type="text" class="datepicker w-100" v-model="start_date" id="start_date" placeholder="از تاریخ">
                        <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
                    </div>
                    <div class="col-lg-3 col-4 mt-1 mt-lg-0">
                        <!-- <label >تا تاریخ</label> -->
                        <input type="text" class="datepicker w-100" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                        <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
                    </div>
                    <div class="col-lg-3 col-2 mt-1 mt-lg-0">
                        <button class="btn btn-primary float-right py-1" @click="getRates()">دریافت</button>
                    </div>
                </div>
                <table class="table table-responsive-sm text-right table-striped mt-4">
                    <thead>
                        <tr>
                            <th colspan="">ردیف</th>
                            <th colspan=""></th>
                            <th colspan="">نام شرکت</th>
                            <th colspan="">سخت گیری کارگزار</th>
                            <th colspan="">موفقیت فرایند</th>
                            <th colspan="">تایید از کل نتایج</th>
                            <th colspan="">تایید کاذب</th>
                            <th colspan="">رد کاذب</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in rates" :key="index">
                            <td class="pr-4">{{index+1}}</td>
                            <td><img :src="item.logo" alt="" width="50" height="50"></td>
                            <!-- <td></td> -->
                            <td>{{item.name}}</td>
                            <td>{{item.strictness_percent}}</td>
                            <td>{{item.success_percent}}</td>
                            <td>{{item.confirm_percent}}</td>
                            <td>{{item.false_confirm_rate}}</td>
                            <td>{{item.false_reject_rate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'jalali-moment';

const instance = axios.create({
  BaseURL:"https://tahavoly.ir"
})
export default {
    data(){
        return{
            reports:[],
            agents:[],
            rates:[],
            download_link:'https://tahavoly.ir',
            start_date:'',
            end_date:'',
            repo_start_date:'',
            repo_end_date:'',
            step:15,
            currentPage:0,
            numOfPage:1,
            repoNextPage:null,
            repoPrevPage:null
        }
    },
    methods:{
        getReports(){
            let sDate=moment.from(this.repo_start_date,'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            let eDate=moment.from(this.repo_end_date,'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')

            let params={
                satart_date__gte:sDate,
                end_date__lte:eDate
            }

            instance.get('/api/report_list/', {params:params}).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.reports = JSON.parse(JSON.stringify(response.data.results))
                        this.repoNextPage = response.data.next
                        this.repoPrevPage = response.data.previous
                    }
                }
            )
        },
        getAgentList(){
            instance.get('/api/agent_list/').then(
                response =>{
                    if(response.status == 200){
                        this.agents = response.data.data
                    }
                }
            )
        },
        getRates(){
            let params={
                start_date:this.start_date,
                end_date:this.end_date
            }
            this.rates = []
            instance.get('/api/strictness/', {params:params}).then(
                response =>{
                    this.rates = response.data.data
                }
            )
        },
        nextPage(){
            if(this.repoNextPage != null){
                instance.get(this.repoNextPage).
                then(
                    response => {
                        this.reports = response.data.results
                        this.repoNextPage = response.data.next
                        this.repoPrevPage = response.data.previous
                    }
                )
            }
        },
        prevPage(){
            if(this.repoPrevPage != null){
                instance.get(this.repoNextPage).
                then(
                    response => {
                        this.reports = response.data.results
                        this.repoNextPage = response.data.next
                        this.repoPrevPage = response.data.previous
                    }
                )
            }
        },
        getPage(pageNumber){
            instance.get('/api/reports/?page='+ pageNumber ).
            then(
                response =>{
                    if(response.status == 200){
                        this.reports = []
                        this.reports = JSON.parse(JSON.stringify(response.data.data))
                    }
                }
            )
        },
        pagination(count){
             if(count % this.step == 0){
                this.numOfPage = parseInt(count / this.step);
            }else{
                this.numOfPage = parseInt(count / this.step) + 1;
            }
        },
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
    },
    mounted(){
        let today = new Date()
        let current = today.setDate(today.getDate()-2)
        let current_jalali = this.convert_to_jalali(current)
        this.start_date = current_jalali
        this.end_date = current_jalali
        this.repo_start_date = current_jalali
        this.repo_end_date = current_jalali
        this.getReports()
        this.getRates()
    }
}
</script>
<style lang="scss" scoped>
    .font{
        font-family: 'IranSans';
    }
    .bg-none{
        background-color: #fff;
    }
</style>