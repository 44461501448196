<template>
  <div dir="rtl" class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <!-- <notifications></notifications> -->

    <side-bar
      :sidebar-item-color="sidebarBackground"
    >
      <!-- :sidebar-background-image="sidebarBackgroundImage" -->
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/">
        <md-icon></md-icon>
        <p>خانه</p>
      </sidebar-link>
      <sidebar-link to="/requests">
        <md-icon></md-icon>
        <p>درخواست ها</p>
      </sidebar-link>
      <sidebar-link to="/reports">
        <md-icon></md-icon>
        <p>گزارشات</p>
      </sidebar-link>
      <sidebar-link to="/errors">
        <md-icon></md-icon>
        <p>توکن خطا</p>
      </sidebar-link>
      <div v-if="isSuperuser" class="text-center px-3">
        <label class="mt-2 mb-0 group w-100">گزارش های مبتنی بر شاخص کلیدی عملکرد</label>
      </div>
      <sidebar-link v-if="isSuperuser" to="/agents">
        <md-icon></md-icon>
        <p>کارگزاران</p>
      </sidebar-link>
      <sidebar-link v-if="isSuperuser" to="/requestStatus">
        <md-icon></md-icon>
        <p>وضعیت درخواست</p>
      </sidebar-link>
      <sidebar-link v-if="isSuperuser" to="/requestType">
        <md-icon></md-icon>
        <p>نوع درخواست</p>
      </sidebar-link>
      <sidebar-link v-if="isSuperuser" to="/errorType">
        <md-icon></md-icon>
        <p>انواع خطا</p>
      </sidebar-link>
      <div v-if="isAdmin" class="text-center px-3">
        <label class="mt-2 mb-0 group w-100">ارزیابی</label>
      </div>
      <sidebar-link v-if="isAdmin" to="assessment">
        <md-icon></md-icon>
        <p>ارزیابی</p>
      </sidebar-link>
      <!-- <sidebar-link to="/user">
        <md-icon>person</md-icon>
        <p>User Profile</p>
      </sidebar-link>
      <sidebar-link to="/table">
        <md-icon>content_paste</md-icon>
        <p>Table list</p>
      </sidebar-link>
      <sidebar-link to="/typography">
        <md-icon>library_books</md-icon>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link>
     
      <sidebar-link to="/upgrade" class="active-pro">
        <md-icon>unarchive</md-icon>
        <p>Upgrade to PRO</p>
      </sidebar-link> -->
       <!-- <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Notifications</p>
      </sidebar-link> -->
    </side-bar>
    
    <div class="main-panel">
      <top-navbar class="d-block"></top-navbar>

      <dashboard-content> </dashboard-content>

      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
import TopNavbar from "./TopNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import auth from "../../services/authService";

const instance = axios.create({
  baseURL:'https://tahavoly.ir'
})
export default {
  components: {
    TopNavbar,
    DashboardContent,
    // ContentFooter,
    MobileMenu,
  },
  data() {
    return {
      sidebarBackground: "blue",
      // sidebarBackgroundImage: require("@/assets/img/sidebar-.jpg")
      role:'',
      isAgent:false,
      isAdmin:false,
      isSuperuser:false,
      isAssessor:false
    };
  },
  methods:{
    getUser(){
      instance.get('/user/').
      then(
        response =>{
            if(response.status == 200){
                this.role = response.data
            }
        }
      ).catch(
        error=>{
          if(error.response.status == 401){
            auth.logout()
          }
        }
      )
    }
  },
  mounted(){
    setTimeout(
      ()=>{
        this.isAdmin = localStorage.getItem('is_staff') === 'true'
        this.isSuperuser = localStorage.getItem('is_superuser') === 'true'
        this.isAgent = localStorage.getItem('is_agent') === 'true'
        this.isAssessor = localStorage,getItem('is_assessor') === 'true'
      },1000
    )
  }
};
</script>
<style scoped>

.text-sm{
  font-size: 12px !important;
  color: #145da1 !important;
}
.group{
  font-size: 13px;
  font-weight: bold;
  color: #01284e; 
  border-bottom: 1px solid #01284e; 
  padding-bottom: 5px; 
  text-align: center !important;
}
.main-panel{
  /* width: calc(100% - 246px) !important; */
  background-color: #f0f0f0 !important;
}
p{
  color: #0b3055 !important;
}
</style>