<template>
    <div class="font-iransans profile-hove" dir="rtl" id="plogo" @click="showMenu()">
        <div class="text-nowrap text-14" >
            <img :src="logo_src.src" height="50" width="50" alt="پروفایل">
            <!-- <span class="mr-1" v-if="fullName != ' '">{{fullName}}</span> -->
            <span class="mr-1">{{username}}</span>
        </div>
        <div v-if="menu" class="bg-white position-absolute border-radius box-shadow mt-2 pt-2 pb-0" >
            <ul class="px-2 ul-hower">
                <li class="style-none text-16 px-3 txt-dark-blue" @click="logout()">
                    <span class="cursor-pointer">خروج</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import auth from '../../services/authService';
const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})
export default {

    data(){
        return{
            fullName:'',
            username:'',
            menu:false,
            logo_src:{"src":require('../../assets/img/user.png')}
        }
    },
    methods:{
        getUser(){
            instance.get('/user/').
            then(
                response =>{
                    if(response.status == 200){
                        // this.fullName = response.data.first_name +' '+ response.data.last_name
                        this.username = response.data.username
                        if(response.data.agent != null){
                            this.logo_src.src=response.data.agent.logo
                        }

                    }
                }
            ).catch(
                error => {
                    if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
        },
        showMenu(){
            if(this.menu){
                this.menu = false
            }else{
                this.menu = true
                let menuIgnore = null
                menuIgnore = document.getElementById('plogo')   
                document.addEventListener('click', (event)=>{
                    let isInsideLogo = true
                    isInsideLogo = menuIgnore.contains(event.target)
                    if(!isInsideLogo){
                        this.menu = false
                    }
                })
            }
        },
        logout(){
            auth.logout()
        },
    },
    mounted(){
        this.getUser()
    }

}
</script>

<style scoped>
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../../../src/assets/font/IRANSansWeb.woff') format('woff');
}
.font-iransans{
    font-family: 'IranSans';
}
.style-none{
    list-style: none;
}
.border-radius{
    border-radius: 0.5rem;
}
.box-shadow{
    box-shadow: 0px 1px 5px 2px rgb(195, 193, 193);
}
.txt-dark-blue{
    color: rgb(13, 98, 172);
}
.ul-hower:hover{
    cursor: pointer;
}
.profile-hove{
    cursor: pointer;
}
.text-14{
    font-size: 14px;
}
</style>