<script>
  import { Doughnut, mixins } from 'vue-chartjs';
  const {reactiveProp } = mixins;
  export default {
    name: 'doughnut-chart',
    extends: Doughnut,
    mixins : [reactiveProp],
    props:{
      options:{
        type: Object,
        default: null
      }
    },
    mounted() {
      //renderChart function renders the chart with the chartData and options object.
      this.renderChart(this.chartData, this.options)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
