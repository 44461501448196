<template>
<div class="row bg-light font-iransans mx-0 px-0">
    <div class="col-12">
        <div class="sticky mt-3 d-flex">
            <ul class="list-none text-right mx-auto" dir="rtl">
                <li class="list-horizontal pl-5 text-nowrap">تعداد ارزیابی نشده: <span class="text-danger text-bold">{{total}}</span></li>
                <li class="list-horizontal pl-5 text-nowrap">تعداد ارزیابی شده: <span class="text-danger text-bold">{{assessmentCount}}</span></li>
                <li class="list-horizontal pl-5 text-nowrap">تعداد مشکوک ها: <span class="text-danger text-bold">{{suspiciousCount}}</span></li>
            </ul>
        </div>
        <div class="row mt-2 pr-3 py-2 ">
            <div class="col-lg-2 pl-4">
                <!-- <label >از تاریخ</label> -->
                <input type="text" class="datepicker w-100" v-model="start_date" id="start_date" placeholder="از تاریخ">
                <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
            </div>
            <div class="col-lg-2 mt-1 mt-lg-0 pl-4">
                <!-- <label >تا تاریخ</label> -->
                <input type="text" class="datepicker w-100" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
            </div>
            <div class="col-lg-4 pl-4">
                <button v-for="(item,index) in STATUSES" :key="index" @click="statusFilterClick(index, item.id)" class="filter-unselect-pink ml-1 float-right mt-2 mt-lg-0" :class="{'status-active':status_active_list[index]}">{{item.title}}</button>
                <button @click="notAssessmentClick()" class="filter-unselect-pink ml-1 float-right mt-2 mt-lg-0" :class="{'status-active':notAssessment}">ارزیابی نشده</button>
            </div>
            <div class="col-lg-2 mt-1 mt-lg-0 pl-4">
                <button class="btn btn-dark-blue float-left py-1 text-large" @click="applyFilter()">اعمال فیلتر</button>
            </div>
        </div>
        <div class="row px-2 font-iransans over-hide mx-0 pb-2">
            <div class="col-lg-6 pr-4 pr-lg-3">
                <div v-for="(item,i) in first_list" :key="i" class="card mt-3 pb-3">
                    <!-- <div class="row px-2 pr-4 pt-1 pb-2">
                        <span v-if="item.assessment == '' || item.assessment.assessor != user " class="text-danger">
                            <i class="fas fa-close"></i>
                            <span class="mr-1">ارزیابی نشده</span>
                        </span>
                        <span v-if="item.assessment.user == user" class="text-success">
                            <i class="fas fa-check"></i> 
                            <span class="mr-1">ارزیابی شده</span>
                        </span>
                    </div> -->
                    <div class="row px-2">
                        <div class="col-lg-4 mt-2 mt-md-0" v-if="item.person != null">
                            <img :src="item.person.person_image" class="media-size media-sm-size" alt="">
                        </div>
                        <div class="col-lg-4 mt-2 mt-md-0" v-if="item.person != null">
                            <img :src="item.person.national_image" class="media-size media-sm-size" alt="">
                        </div>
                        <div class="col-lg-4 mt-2 mt-md-0" v-if="item.person != null">
                            <video class="media-size" controls>
                                <source :src="item.person.person_video" type="video/mp4">
                            </video>                    
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-lg-3 pr-4" v-for="(op,j) in OPINIONS" :key="j">
                            <!-- @change="showValues(i)" -->
                            <input type="radio" class="form-radio float-right mt-1" :value="op.id" v-model="first_list_values[i]" > 
                            <span class="float-right mr-1 text-nowrap">{{op.title}}</span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-3 pr-4">
                            <span class="float-right mr-1 text-nowrap">{{ item.live_desc }}</span>
                        </div>
                        
                        <div class="col-lg-7 pl-5 pt-3" @click="firstListItemSubmit(i)">
                            <button v-if="item.assessment != null" class="btn btn-success py-1 float-left">تغییر</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div v-for="(item,i) in second_list" :key="i" class="card mt-3 pb-3">
                    <!-- <div class="row px-2 pr-4 pt-1 pb-2">
                        <span v-if="item.assessment == '' || item.assessment.assessor != user" class="text-danger">
                            <i class="fas fa-close"></i>
                            <span class="mr-1">ارزیابی نشده</span>
                        </span>
                        <span v-if="item.assessment.user == user" class="text-success">
                            <i class="fas fa-check"></i> 
                            <span class="mr-1">ارزیابی شده</span>
                        </span>
                    </div> -->
                    <div class="row px-2">
                        <div class="col-lg-4 mt-2 mt-md-0" v-if="item.person != null">
                            <img :src="item.person.person_image" class="media-size media-sm-size" alt="">
                        </div>
                        <div class="col-lg-4 mt-2 mt-md-0" v-if="item.person != null">
                            <img :src="item.person.national_image" class="media-size media-sm-size" alt="">
                        </div>
                        <div class="col-lg-4 mt-2 mt-md-0" v-if="item.person != null">
                            <video class="media-size" controls>
                                <source :src="item.person.person_video" type="video/mp4">
                            </video>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-lg-3 pr-4" v-for="(op, j) in OPINIONS" :key="j">
                            <input type="radio" class="form-control float-right mt-1" :value="op.id" v-model="second_list_values[i]">
                            <span class="float-right mr-1">{{op.title}}</span>
                        </div>
                        <!-- <div class="col-lg-3 pl-4">
                            <button v-if="item.assessment != ''" class="btn btn-success py-1 float-left" @click="(i)">تغییر</button>
                        </div> -->
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-3 pr-4">
                            <span class="float-right mr-1 text-nowrap">{{ item.live_desc }}</span>
                        </div>
                        
                        <div class="col-lg-7 pl-5 pt-3" @click="secondListItemSubmit(i)">
                            <button v-if="item.assessment != null" class="btn btn-success py-1 float-left">تغییر</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!finalSubmitDisable" class="row px-3 mt-4">
            <div class="col-3 col-md-5"></div>
            <div class="col-6 col-md-2 px-3">
                <button class="btn btn-success w-100" @click="finalSubmit()">ثبت ارزیابی</button>
            </div>
        </div>
        <div class="row mt-3 font-iransans pb-3" dir="ltr">
            <div class="col-3 col-lg-1 pl-3">
                <button class="btn-dark-blue circle float-left" @click="prevPage()">❮</button>
            </div>
            <div class="col-4 col-lg-2 text-center text-dark-blue text-nowrap px-0">
                <span>صفحه</span> <span><input class="w-25 input px-2" min="1" type="number" v-model="currentPage"></span> <span>از</span> <span>{{numOfPage}}</span> <span @click="selectPage()" class="float-left button px-2 pb-1">برو</span>
            </div>
            <div class="col-2 col-lg-1">
                <button class="btn-dark-blue ml-1 circle float-right" @click="nextPage()">❯</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from 'axios';
import moment from 'jalali-moment';
import auth from '../../services/authService';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})

export default {
    data(){
        return {
            user:null,
            STATUSES:[],
            statusFilter:[],
            notAssessment:true,
            status_active_list:[false, false, false],
            baseUrl:'https://tahavoly.ir',
            OPINIONS:[],
            first_list:[],
            second_list:[],
            first_list_values:[],
            second_list_values:[],
            // first_list_gender:[],
            // second_list_gender:[],
            start_date:'',
            end_date:'',
            numOfPage:0,
            pageNumber:0,
            step:20,
            currentPage:0,
            prevPageLink:'',
            nextPageLink:'',
            total:0,
            suspiciousCount:0,
            changedCount:0,
            assessmentCount:0,
            finalSubmitDisable:true,
            flag:false
        }
    },
    methods:{
        getOpinion(){
            instance.get('/api/opinion_list/').
            then(
                response =>{
                    if(response.status == 200){
                        this.OPINIONS = JSON.parse(JSON.stringify(response.data))
                        this.STATUSES = JSON.parse(JSON.stringify(response.data))
                    }
                }
            )
        },
        getDocuments(start_date, end_date, pageNumber=null){
            let sDate=moment.from(start_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            let eDate=moment.from(end_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')

            let params={
                // person__isnull:false,
                created__range:sDate + ','+ eDate,
                assessment__opinion__in:'',
                assessment__isnull: this.notAssessment,
            }
            this.statusFilter.length !=0 ? params.assessment__opinion__in = this.statusFilter.toString() : params.assessment__opinion__in=null
            if (moment(sDate, 'YYYY-MM-DD') < moment('2023-12-22', "YYYY-MM-DD")){
                let oldMediaParams={
                    created__range:sDate + ',' + eDate
                }
                return instance.get('/api/old-media/receive/', {params:oldMediaParams})
            }
            return instance.get('/api/document_list/', {params:params})
            
        },
        applyFilter(){
            // this.getDocuments()
            const loading = this.$vs.loading({
                type:'square',
                text:'... در حال دریافت اطلاعات',
            })
            this.first_list = []
            this.second_list = []
            this.first_list_values = []
            this.second_list_values = []
            // this.first_list_gender = []
            // this.second_list_gender = []
            this.getDocuments(this.start_date, this.end_date).
            then(
                response =>{
                    if(response.status == 200){
                        this.total = response.data.count
                        this.prevPageLink = response.data.previous
                        this.nextPageLink = response.data.next
                        this.flag=false
                        this.divideTwoSection(response.data.results)
                        if(this.flag){
                            this.finalSubmitDisable = false
                        }
                        this.pagination(this.total)
                        this.currentPage = 1
                    }
                }
            ).catch(
                error =>{
                    if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
            if(this.first_list.length != 0){
                setTimeout(() => {
                    loading.close()
                }, 1500);
            }else{
                setTimeout(() => {
                    loading.close()
                }, 3000);
            }
        },
        divideTwoSection(list){

            this.first_list=[]
            this.second_list=[]
            this.first_list_values=[]
            this.second_list_values=[]
            // const loading = this.$vs.loading({
            //     type:'square',
            //     text:'... در حال دریافت اطلاعات',
            // })
            let mid = parseInt(list.length / 2)
            for(let i=0; i < mid; i++){
                this.first_list.push(list[i])
                if(list[i].assessment != null){
                    this.first_list_values.push(list[i].assessment.opinion)
                }else{
                    this.flag = true
                    this.first_list_values.push(list[i].token_state)
                }
                // this.first_list_gender.push(list[i].gender)
            }
            for(let i=mid; i < list.length; i++){
                this.second_list.push(list[i])
                if(list[i].assessment != null){
                    this.second_list_values.push(list[i].assessment.opinion)
                }else{
                    this.flag = true
                    this.second_list_values.push(list[i].token_state)
                }
                // this.second_list_gender.push(list[i].gender)
            }
            this.getChange()
            // loading.close()
        },
        finalSubmit(){
            let sDate=moment.from(this.start_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            // let eDate=moment.from(end_date, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
            let list = []
            for (let i = 0; i < this.first_list.length; i++) {
                if(this.first_list[i].assessment == null){
                    let assessment = {
                        token:null,
                        opinion:null,
                        // gender:''
                    }
                    assessment.token = this.first_list[i].person.token
                    assessment.opinion = this.first_list_values[i]
                    // assessment.gender = this.first_list_gender[i]
                    list.push(assessment)
                }
                else
                    continue;
            }
            for (let i = 0; i < this.second_list.length; i++) {
                if(this.second_list[i].assessment == null){
                    let assessment = {
                        token:null,
                        opinion:null,
                        // gender:''
                    }
                    assessment.token = this.second_list[i].person.token
                    assessment.opinion = this.second_list_values[i]
                    // assessment.gender = this.second_list_gender[i]
                    list.push(assessment)
                }else
                    continue
            }
            if (moment(sDate, 'YYYY-MM-DD') < moment('2023-12-22', "YYYY-MM-DD")){
                instance.post('/api/old/assessment/', list).
                then(
                    response =>{
                        if(response.status == 200){
                            this.finalSubmitDisable = true
                            if(response.data.finish){
                                this.showNotif('ثبت ارزیابی با موفقیت انجام شد','تعداد مجاز ارزیابی شما به پایان رسید','success')
                            }else{
                                this.showNotif('ثبت ارزیابی با موفقیت انجام شد','','success')
                            }
                            this.getChange()
                        }
                    }
                ).catch(
                    error =>{
                        if(error.response.status == 401){
                            auth.logout()
                        }
                    }
                )
            }
            else{
                instance.post('/api/assessment/', list).
                then(
                    response =>{
                        if(response.status == 200){
                            this.finalSubmitDisable = true
                            if(response.data.finish){
                                this.showNotif('ثبت ارزیابی با موفقیت انجام شد','تعداد مجاز ارزیابی شما به پایان رسید','success')
                            }else{
                                this.showNotif('ثبت ارزیابی با موفقیت انجام شد','','success')
                            }
                            this.getChange()
                        }
                    }
                ).catch(
                    error =>{
                        if(error.response.status == 401){
                            auth.logout()
                        }
                    }
                )
            }
        },
        statusFilterClick(index, id){
            if(this.status_active_list[index]){
                let i = this.statusFilter.indexOf(id)
                if (i>-1){
                    this.statusFilter.splice(i, 1)
                }
                this.statusFilter = JSON.parse(JSON.stringify(this.statusFilter))
                this.status_active_list[index] = false
                this.status_active_list = JSON.parse(JSON.stringify(this.status_active_list))
            }else{
                this.statusFilter.push(id)
                this.statusFilter = JSON.parse(JSON.stringify(this.statusFilter))
                this.status_active_list[index] = true
                this.status_active_list = JSON.parse(JSON.stringify(this.status_active_list))
            }
        },
        notAssessmentClick(){
            if(this.notAssessment)
                this.notAssessment = false
            else
                this.notAssessment = true
        },
        pagination(count){
             if(count % this.step == 0){
                this.numOfPage = parseInt(count / this.step);
            }else{
                this.numOfPage = Math.floor(count / this.step) + 1;
            }
        },
        nextPage(){
            this.first_list=[]
            this.second_list=[]
            this.first_list_values=[]
            this.second_list_values=[]
            if(this.nextPageLink != null){
                instance.get(this.nextPageLink).
                then(
                    response=>{
                        this.total = response.data.count
                        this.prevPageLink = response.data.previous
                        this.nextPageLink = response.data.next
                        this.flag = false;
                        this.divideTwoSection(response.data.results)
                        if(this.flag){
                            this.finalSubmitDisable = false
                        }
                    }
                )
                this.currentPage++         
            }
        },
        prevPage(){
            if(this.prevPageLink != null){
                instance.get(this.prevPageLink).
                then(
                    response=>{
                        this.total = response.data.count
                        this.prevPageLink = response.data.previous
                        this.nextPageLink = response.data.next
                        this.flag = false;
                        this.divideTwoSection(response.data.results)
                        if(this.flag){
                            this.finalSubmitDisable = false
                        }
                    }
                )
                this.currentPage--
            }
        },
        selectPage(){
            this.first_list = []
            this.first_list_values = []
            this.second_list = []
            this.second_list_values = []
            // this.first_list_gender = []
            // this.second_list_gender = []
            this.getDocuments(this.start_date, this.end_date, this.currentPage).then(
                response =>{
                    if(response.status == 200 ){
                        this.flag = false;
                        this.divideTwoSection(response.data.results)
                        if(this.flag){
                            this.finalSubmitDisable = false
                        }
                        // this.getChange()
                    }
                }
            )
        },
        firstListItemSubmit(index){
            let assessment = {
                token:null,
                assessor:null,
                opinion:null,
                // gender:null
            }
            assessment.token = this.first_list[index].person.token
            assessment.assessor = +localStorage.getItem('user_id')
            // assessment.assessor = 1
            assessment.opinion = this.first_list_values[index]
            // assessment.gender = this.first_list_gender[index]
            instance.put('/api/assessment/', assessment).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.first_list[index].assessment = response.data.data
                        this.showNotif('ویرایش با موفقیت انجام شد.','','success')
                        this.getChange()
                    }
                }
            ).catch(
                error =>{
                    // console.log(error)
                }
            )
        },
        secondListItemSubmit(index){
            let assessment = {
                token:null,
                assessor:null,
                opinion:null,
                gender:null
            }
            assessment.token = this.second_list[index].person.token
            assessment.assessor = +localStorage.getItem('user_id')
            // assessment.assessor = 1
            assessment.opinion = this.second_list_values[index]
            // assessment.gender = this.second_list_gender[index]
            instance.put('/api/assessment/', assessment).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.second_list[index].assessment = response.data.data
                        this.showNotif('ویرایش با موفقیت انجام شد.','','success')
                        this.getChange()
                    }
                }
            ).catch(
                error =>{
                    if(error.response.status == 400){
                        this.showNotif('ارزیابی','این سند یک بار ارزیابی شده است','danger')
                    }
                }
            )
        },
        getChange(){
            let params={
                start_date:this.start_date,
                end_date:this.end_date
            }
            instance.get('/api/calculate_changed/', params).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.changedCount = response.data.changed_count
                        this.suspiciousCount = response.data.suspicious_count
                        this.assessmentCount = response.data.assessmented_count
                    }
                }
            )
        },
        
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
        showNotif(title, text, color){
            this.$vs.notification({
                title:title,
                text:text,
                border:color,
                position:'top-center'
            })
        }
    },
    mounted(){
        this.getOpinion()
        let today = new Date()
        let yesterday = today.setDate(today.getDate()-2)
        let yesterday_jalali = this.convert_to_jalali(yesterday)
        this.start_date = yesterday_jalali
        this.end_date = yesterday_jalali
        this.pageNumber = 1
        this.applyFilter(this.start_date, this.end_date, this.pageNumber)
        this.user = localStorage.getItem('user_id')
    }
}
</script>
<style lang="scss" scoped>
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../../../src/assets/font/IRANSansWeb.woff') format('woff');
}

@media screen and(max-width: 576px){
    .media-sm-size{
        // width: 75% !important;
        height: 236px !important;
    }
}
@media only screen and(min-width:576px){
    .list-horizontal{
        float: right !important;
    }
}
@media only screen and(max-width:450px){
    div.sticky{
        width: 100% !important;
    }
}
.card{ 
    border: 1px solid transparent;
    border-radius: 0.8rem !important;
    box-shadow: 0px 1px 8px 2px #a0a0a0;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

}
.media-size{
    // width: 177px !important;
    height: 236px !important;
}
.form-control{
    width: 15px !important;
    height: 15px !important;
}
.font-iransans{
    font-family: 'IranSans';
}
.over-hide{
    overflow-x: hidden !important;
}
.datepicker{
    border-radius: 0.5rem;
    box-shadow: 0x 2px 5px 1px #bbbbbb !important;
    color: #145da1;
    border-color: #145da1;
}
.bg-custom{
    background-color: #e0e1e2 !important;
}
.circle{
    border-radius: 50%;
    width: 35px;
    height: 35px;
}
.filter-unselect-pink{
    border: 1px solid #f95d6a;
    border-radius: 0.5rem;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #fff;
    color: #f95d6a;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 4px 6px -1px #fe92aa;
}
.status-active{
    background-color: #f95d6a !important;
    color: #fff !important;
}
.btn-dark-blue{
    background-color: #145da1;
    color: white;
    border: 1px solid transparent;
    border-radius: 1.5rem;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    font-size: 12px;
}
div.sticky{
    width: 50%;
    margin: auto;
    position: -webkit-sticky;
    position: sticky !important;
    top:0 !important;
    padding-top: 17px !important;
    background-color: #4294ff;
    box-shadow: 1px 3px 8px rgba(60, 60, 60, 0.499);
    color: white;
    font-size: 18px;
    z-index: 500;
}

.list-none{
    list-style: none;
}
.bg-light{
    background-color: #f0f0f0 !important;
}
.text-bold{
    font-weight: bold;
}
.text-large{
    font-size: 15px;
}
.button{
    border: 1px solid #145da1;
    color: #145da1;
    background-color: #fff;
    border-radius: 1.5rem;
    cursor:default;
}
.button:hover{
    color: #fff;
    background-color: #145da1;
}
.input{
    border: 1px solid #145da1;
    border-radius: 0.5rem;
    width: 35%;
}
</style>    