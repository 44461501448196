<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>
<style>

@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../src/assets/font/IRANSansWeb.woff') format('woff');
}
#app {
  font-family: IranSans, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: #cbcbcb;
  overflow-x: hidden;
}
</style>
