<template>
    <div class="row">
        <div class="col-12">
            <div class="row mt-2 pr-3 py-2 font">
                <div class="col-lg-2">
                    <!-- <label >از تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="start_date" id="start_date" placeholder="از تاریخ">
                    <date-picker v-model="start_date" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="start_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <!-- <label >تا تاریخ</label> -->
                    <input type="text" class="datepicker w-100" v-model="end_date" id="end_date" placeholder="تا تاریخ">
                    <date-picker v-model="end_date" class="datepicker" format="jYYYY-jMM-jDD" display-format="jYYYY-jMM-jDD" locale="fa" clearable element="end_date"></date-picker>
                </div>
                <div class="col-lg-2 mt-1 mt-lg-0">
                    <button class="btn btn-primary float-right py-1" @click="applyFilter()">دریافت</button>
                </div>
            </div>
            <div class="row mt-1 px-3">
                <div class="col-lg-6 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">درخواست به تفکیک کارگزار</label>
                        <bar-chart :chartData="agentChartData" :options="requestOptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-6 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">کارگزار بر حسب وضعیت درخواست</label>
                        <bar-chart :chartData="reqStatusChartData" :options="reqTypeOptions" style="height:270px !important"></bar-chart>
                    </div>

                </div>
            </div>
            <div class="row mb-2 px-3">
                <div class="col-lg-5 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">کارگزار بر حسب نوع درخواست</label>
                        <bar-chart :chartData="reqTypeChartData" :options="reqTypeOptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
                <div class="col-lg-7 p-2 py-1">
                    <div class="card">
                        <label class="text-right font">کارگزار بر حسب کد خطا <span class="text-danger text-14 mr-2">(نیاز به بروزرسانی)</span></label>
                        <bar-chart :chartData="errorChartData" :options="reqTypeOptions" style="height:270px !important"></bar-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import barChart from '../../components/barChart.vue';
import moment from 'jalali-moment';
import auth from '../../services/authService';
const instance = axios.create({
    baseURL:'https://tahavoly.ir'
})
export default {
    components:{
        barChart,
    },
    data(){
        let errorColors=['#0d6efd','#6c757d','#dc3545','#ffc107','#17a2b8','#ff832b','#2ba745','#343a40']
        let errors=['201','202','203','204','205','206','207','208',]
        return{
            agents:['آی تی سار','یو آیدی','آناهیتا','غدیر','آدانیک','حامی سیستم شربف','پارت'],
            statusColors:['#1fc417','#f1c21b','#f95d6a','#6c757d', '#ff832b','#0d6efd','#e91035'],
            types:['ثبت نام','ویرایش'],
            typeColors:['#21abc9','#f95d6a'],
            start_date:'',
            end_date:'',
            agentChartData:{
                labels: [],
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#4770b3',
                        data: [],
                    },
                ]
            },
            requestOptions: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }]
                },
                legend:{
                    display:false
                    // position:'bottom'
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            //requestType chart data
            reqTypeChartData:{
                labels: [],
                datasets: [
                ]
            },
            reqTypeOptions: {
                scales:{
                    xAxes:[{
                        gridLines:{
                            display:false
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                    yAxes:[{
                        pointLabels: {
                            fontFamily: "IranSans"
                        },
                        ticks: {
                            fontFamily: "IranSans",
                        }
                    }],
                },
                legend:{
                    labels:{
                        fontFamily:'IranSans'
                    },
                    position:'bottom'
                },
                tooltips:{
                    titleFontFamily:'IranSans',
                    bodyFontFamily:'IranSans'
                },
                responsive: true,
                maintainAspectRatio: false,
            },

            //request status chart data
            reqStatusChartData:{
                labels: [],
                datasets: []
            },

            //error chart data
            errorChartData:{
                labels: this.agents,
                datasets: [
                    {
                        label: errors[0],
                        backgroundColor: errorColors[0],
                        data: [121,96,192,252,180,243],
                    },
                    {
                        label: errors[1],
                        backgroundColor: errorColors[1],
                        data: [47,38,192,99,27,95],
                    },
                    {
                        label: errors[2],
                        backgroundColor: errorColors[2],
                        data: [52,47,85,104,35,106],
                    },
                    {
                        label: errors[3],
                        backgroundColor: errorColors[3],
                        data: [76,65,113,162,83,147],
                    },{
                        label: errors[4],
                        backgroundColor: errorColors[4],
                        data: [1,0,0,1,1,1],
                    },{
                        label: errors[5],
                        backgroundColor: errorColors[5],
                        data: [25,17,34,43,11,42],
                    },{
                        label: errors[6],
                        backgroundColor: errorColors[6],
                        data: [385,307,609,801,273,773],
                    },{
                        label: errors[7],
                        backgroundColor: errorColors[7],
                        data: [3,5,9,13,4,11],
                    },
                ]
            },
        }
    },
    methods:{
        applyFilter(){
            const loading = this.$vs.loading({
                type:'square',
                text:'... در حال دریافت اطلاعات'
            })
            this.countPerAgent(this.start_date, this.end_date)
            this.countPerAgentAndState(this.start_date, this.end_date)
            this.countPerAgentAndType(this.start_date, this.end_date)
            if(this.reqStatusChartData.datasets.length != 0){
                setTimeout(() => {
                    loading.close()
                }, 1500);
            }else{
                setTimeout(() => {
                    loading.close()
                }, 2000);
            }
        },
        countPerAgent(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_agent/', {params:params}).
            then(
                response =>{
                    if(response.status==200){
                        this.agentChartData.labels = []
                        this.agentChartData.datasets[0].data = []
                        let data = []
                        let labels = []
                        response.data.results.forEach(item =>{
                            labels.push(item.agent_name)
                            data.push(item.token_count)
                        })
                        this.agentChartData = {
                            labels: labels,
                            datasets: [
                                {
                                    label: '',
                                    backgroundColor: '#4770b3',
                                    data: data,
                                },
                            ]
                        }
                    }
                }
            ).catch(
                error => {
                    if(error.response.status == 401){
                        auth.logout()
                    }
                }
            )
        },
        countPerAgentAndState(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_agent_and_state/', {params:params}).
            then(
                response =>{
                    if(response.status == 200){
                        this.reqStatusChartData={
                            labels: this.agents,
                            datasets:[
                                {
                                    label: 'تایید شده',
                                    backgroundColor: this.statusColors[0],
                                    data: [response.data.results[0].verify,response.data.results[1].verify,response.data.results[2].verify,response.data.results[3].verify,response.data.results[4].verify,response.data.results[5].verify,response.data.results[6].verify],
                                },
                                {
                                    label: 'ایجاد شده',
                                    backgroundColor: this.statusColors[1],
                                    data: [response.data.results[0].created,response.data.results[1].created,response.data.results[2].created,response.data.results[3].created,response.data.results[4].created,response.data.results[5].created,response.data.results[6].created],
                                },
                                {
                                    label: 'منقضی شده',
                                    backgroundColor: this.statusColors[2],
                                    data: [response.data.results[0].expired,response.data.results[1].expired,response.data.results[2].expired,response.data.results[3].expired,response.data.results[4].expired,response.data.results[5].expired,response.data.results[6].expired],
                                },
                                {
                                    label: 'پایان مهلت احراز هویت غیر جضوری',
                                    backgroundColor: this.statusColors[3],
                                    data: [response.data.results[0].verify_expired,response.data.results[1].verify_expired,response.data.results[2].verify_expired,response.data.results[3].verify_expired,response.data.results[4].verify_expired,response.data.results[5].verify_expired,response.data.results[6].verify_expired],
                                },
                                {
                                    label: 'تصویر ثبت احوال دریافت شده',
                                    backgroundColor: this.statusColors[4],
                                    data: [response.data.results[0].image_recived,response.data.results[1].image_recived,response.data.results[2].image_recived,response.data.results[3].image_recived,response.data.results[4].image_recived,response.data.results[5].image_recived,response.data.results[6].image_recived],
                                },
                                {
                                    label: 'چک شده',
                                    backgroundColor: this.statusColors[5],
                                    data: [response.data.results[0].checked,response.data.results[1].checked,response.data.results[2].checked,response.data.results[3].checked,response.data.results[4].checked,response.data.results[5].checked,response.data.results[6].checked],
                                },
                                {
                                    label: 'رد شده',
                                    backgroundColor: this.statusColors[6],
                                    data: [response.data.results[0].rejected,response.data.results[1].rejected,response.data.results[2].rejected,response.data.results[3].rejected,response.data.results[4].rejected,response.data.results[5].rejected,response.data.results[6].rejected],
                                },
                            ],
                        }
                    }
                }
            ).catch(error => {})
        },
        countPerAgentAndType(start_date, end_date){
            let params={
                start_date:start_date,
                end_date:end_date
            }
            instance.get('/api/tokens/count_per_agent_and_type/', {params:params}).
            then(
                response =>{
                    if(response.status == 200 ){
                        this.reqTypeChartData = {
                            labels: this.agents,
                            datasets: [
                                {
                                    label: 'ثبت نام',
                                    backgroundColor: this.typeColors[0],
                                    data: [response.data.results[0].register,response.data.results[1].register,response.data.results[2].register,response.data.results[3].register,response.data.results[4].register,response.data.results[5].register,response.data.results[6].register],
                                },
                                {
                                    label: 'ویرایش',
                                    backgroundColor: this.typeColors[1],
                                    data: [response.data.results[0].edit,response.data.results[1].edit,response.data.results[2].edit,response.data.results[3].edit,response.data.results[4].edit,response.data.results[5].edit,response.data.results[6].edit],
                                },
                            ]
                        }
                    }
                }
            ).catch(error => {})
        },
        convert_to_jalali(date){
            return moment(date).format('jYYYY-jMM-jDD')
        },
    },
    mounted(){
        let today = new Date()
        let yesterday = today.setDate(today.getDate()-2)
        let yesterday_jalali = this.convert_to_jalali(yesterday)
        this.start_date = yesterday_jalali
        this.end_date = yesterday_jalali
        this.applyFilter()
    }
}
</script>
<style scoped>
@font-face{
  font-family: 'IranSans';
  src: local('IranSans'), url('../../../src/assets/font/IRANSansWeb.woff') format('woff');
}
.card{
    display: flex;
    border-radius: 1rem;
    box-shadow: 0px 4px 6px 0px #bbbbbb;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.font{
    font-family: 'IranSans';
}
.datepicker{
    border-radius: 0.5rem;
    box-shadow: 0x 2px 5px 1px #bbbbbb !important;
}
</style>